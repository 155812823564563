import React, { useState } from 'react';
import './Sidebar.css';
import logo from './Logomarca Cooperval-05.png';
import styled from "styled-components";
import { ImExit } from "react-icons/im";
import DashboardVideos from './dashboard-video.js';
import { FaVideo } from "react-icons/fa";
import { IoClose } from "react-icons/io5";

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100vh; /* Ocupa toda a altura da tela */
  overflow: hidden; /* Evita rolagem extra */
  gap: 25px;

  @media (max-width: 480px) {
    overflow-y: scroll;
  }
  @media (max-width: 400px) {
    overflow-y: scroll;
  }
`;

const BackgroundImage = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;

  @media (max-width: 480px) {
    display: none;
  }
  
  @media (max-width: 400px) {
    display: none;
  }
`;


const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row; 
  justify-content: center;
  align-items: center;
  gap: 30px; 
  z-index: 1; 

  @media (max-width: 480px) {
    flex-direction: column;
  }
  @media (max-width: 400px) {
    flex-direction: column;
  }
`;

const Button = styled.button`
  width: 250px;
  height: 90px;
  padding: 10px 20px;
  font-size: 24px;
  cursor: pointer;
  color: white; /* Cor do texto */
  background: rgb(7, 102, 51); /* Fundo verde translúcido */
  border: 1px solid rgb(7, 102, 51); /* Borda verde translúcida */
  border-radius: 10px; /* Bordas arredondadas */
  backdrop-filter: blur(10px); /* Efeito de desfoque */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Sombra leve */
  transition: transform 0.1s, background 0.3s, border 0.3s;

  &:hover {
    transform: scale(1.2);
    background: rgba(7, 102, 51); /* Fundo verde um pouco mais opaco no hover */
    border: 1px solid rgba(7, 102, 51, 0.1); /* Borda verde mais opaca no hover */
  }
`;

const ButtonDesat = styled.button`
  width: 250px;
  height: 90px;
  padding: 10px 20px;
  font-size: 24px;
  color: white; /* Cor do texto */
  background: gray; /* Fundo verde translúcido */
  border: 1px solid gray; /* Borda verde translúcida */
  border-radius: 10px; /* Bordas arredondadas */
  backdrop-filter: blur(10px); /* Efeito de desfoque */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Sombra leve */
  transition: transform 0.1s, background 0.3s, border 0.3s;

  @media (max-width: 480px) {
    display: none;
  }
  
  @media (max-width: 400px) {
    display: none;
  }
 
`;


const Button2 = styled.button`
  width: 250px;
  height: 90px;
  padding: 10px 20px;
  top: 2;
  left: 0;
  font-size: 24px;
  cursor: pointer;
  color:  rgb(7, 102, 51); /* Cor do texto */
  border: 1px solid rgb(7, 102, 51); /* Borda verde translúcida */
  border-radius: 10px; /* Bordas arredondadas */
  backdrop-filter: blur(10px); /* Efeito de desfoque */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Sombra leve */
  transition: transform 0.1s, background 0.3s, border 0.3s;

  &:hover {
    transform: scale(1.2);
    
  }


`;

const ButtonSide1 = styled.button`
  cursor: pointer;
  border: none;
  background-color: #e9ebec;
  color: black;
  font-size: 22px;


  &.button-exit{
    position: absolute;
    top: 0;
    right: 0;
    padding: 1vw;
  }

  &.button-apresentacao{
    position: absolute;
    top: 0;
    left: 0;
    padding: 1vw;
  }

`;


const ButtonContainer = styled.div`
  display: flex;
  justify-content: start; 
`;

const ButtonClose = styled.button`
  cursor: pointer;
  border: none;
  background-color: #515151;
  transition: transform 1.5s;
  &:hover {
    transform: scale(1.5);
  }
`;



const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;

  @media (max-width: 480px) {
    width: 95%;
    height: 95%;
  }
  
  @media (max-width: 400px) {
    width: 95%;
    height: 95%;
  }
  
`;
const ModalContent = styled.div`
  background-color: #515151;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  max-height: 90vh; 
  overflow-y: auto; 

  

`;
const Grid = ({ urlFront, urlLogin, urlApi, acessos }) => {

  const id = localStorage.getItem('id');

  const handleGestao = () => {
    const url = `/gestao/`;
    window.location.href = url;
  };
  const handleBoletins = () => {
    const url = `/boletins/`;
    window.location.href = url;
  };


  const handleGerenciar = () => {
    const url = `${urlFront}/cadastro-usuarios`;
    window.location.href = url;
  };

  const handlePainel = () => {
    const url = `/painel-de-monitoramento/`;
    window.location.href = url;
  };

  const handleTreinamentos = () => {
    const url = `/treinamento/`;
    window.location.href = url;
  };

  const handlePlanejamentoEntressafra = () => {
    const url = `/planejamento-entressafra/`;
    window.location.href = url;
  };

  const handleExit = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('id');
    localStorage.removeItem('usuario');

    const url = `${urlLogin}`;
    window.location.href = url;
  };


  //Modal que mostra as apresentações
  const [isModalOpenVideo, setIsModalOpenVideo] = useState(false);
  const modalVideo = () => {
    setIsModalOpenVideo(!isModalOpenVideo);
  };
  //------------------------------------------------------------------------------//
  const portalAccess = acessos.find(
    (acesso) => acesso.id_usuario == id && acesso.modulo === "portal"
  );
  const gestaoAccess = acessos.find(
    (acesso) => acesso.id_usuario == id && acesso.modulo === "gestao"
  );
  const boletinsAccess = acessos.find(
    (acesso) => acesso.id_usuario == id && acesso.modulo === "boletinsWeb"
  );
  const PainelAccess = acessos.find(
    (acesso) => acesso.id_usuario == id && acesso.modulo === "painelMonitoramento"
  );
  const treinamentosAccess = acessos.find(
    (acesso) => acesso.id_usuario == id && acesso.modulo === "treinamentos"
  );
  const planejamentoAccess = acessos.find(
    (acesso) => acesso.id_usuario == id && acesso.modulo === "planejamentoEntressafra"
  );
  return (
    <>
      {isModalOpenVideo && (
        <ModalContainer>
          <ModalContent>
            <ButtonContainer>
              <ButtonClose onClick={() => {
                modalVideo();
              }}>
                <IoClose color={"red"} size={25} />
              </ButtonClose>
            </ButtonContainer>
            <DashboardVideos urlApi={urlApi} />
          </ModalContent>
        </ModalContainer>
      )}
      <Container>
        <ButtonSide1 className="button-exit" onClick={handleExit} title={"Sair"}>
          <ImExit />
        </ButtonSide1>
        <ButtonSide1 className="button-apresentacao" onClick={modalVideo} title={"Apresentação"}>
          <FaVideo />
        </ButtonSide1>

        <BackgroundImage src={logo} style={{ width: '300px', height: '70px', padding: '0px 20px 20px 0px' }} alt="Logo" />
        <ContentWrapper>

          {(gestaoAccess && (gestaoAccess.tipo_acesso === "A" || gestaoAccess.tipo_acesso === "G" || gestaoAccess.tipo_acesso === "U")) && (
            <Button onClick={handleGestao}>Gestão</Button>
          )}

          {(boletinsAccess && (boletinsAccess.tipo_acesso === "Y")) && (
            <Button onClick={handleBoletins}>Boletins Web</Button>
          )}

          {(PainelAccess && (PainelAccess.tipo_acesso === "Y")) && (
            <Button onClick={handlePainel}>Painel de Monitoramento</Button>
          )}

          {(treinamentosAccess && (treinamentosAccess.tipo_acesso === "Y")) && (
            <Button onClick={handleTreinamentos}>Treinamentos</Button>
          )}

        </ContentWrapper>
        <ContentWrapper>

          {(planejamentoAccess && (planejamentoAccess.tipo_acesso === "Y")) && (
            <Button onClick={handlePlanejamentoEntressafra} type="button">Planejamento Entressafra</Button>
          )}

          {(portalAccess && (portalAccess.tipo_acesso === "A" || portalAccess.tipo_acesso === "G")) && (
            <ButtonDesat >Metas</ButtonDesat>
          )}

          {(portalAccess && (portalAccess.tipo_acesso === "A" || portalAccess.tipo_acesso === "G")) && (
            <ButtonDesat >Estoque Mínimo</ButtonDesat>
          )}

          {(portalAccess && (portalAccess.tipo_acesso === "A" || portalAccess.tipo_acesso === "G")) && (
            <ButtonDesat >Segurança Patrimonial</ButtonDesat>
          )}

        </ContentWrapper>
        <ContentWrapper>

          {(portalAccess && (portalAccess.tipo_acesso === "A" || portalAccess.tipo_acesso === "G")) && (
            <ButtonDesat >Segurança do Trabalho</ButtonDesat>
          )}

          {(portalAccess && (portalAccess.tipo_acesso === "A" || portalAccess.tipo_acesso === "G")) && (
            <ButtonDesat >Procedimentos</ButtonDesat>
          )}

          {(portalAccess && (portalAccess.tipo_acesso === "A" || portalAccess.tipo_acesso === "G")) && (
            <ButtonDesat >Ocorrências</ButtonDesat>
          )}

          {(portalAccess && (portalAccess.tipo_acesso === "A" || portalAccess.tipo_acesso === "G")) && (
            <ButtonDesat >Controle de Frota</ButtonDesat>
          )}

        </ContentWrapper>

        <ContentWrapper>

          {(portalAccess && (portalAccess.tipo_acesso === "A" || portalAccess.tipo_acesso === "G")) && (
            <ButtonDesat >Controle de Reagentes</ButtonDesat>
          )}

          {(portalAccess && (portalAccess.tipo_acesso === "A" || portalAccess.tipo_acesso === "G")) && (
            <ButtonDesat >Central da TI</ButtonDesat>
          )}

          {(portalAccess && (portalAccess.tipo_acesso === "A")) && (
            <Button2 onClick={handleGerenciar} type="button"> Gerenciar Usuários </Button2>
          )}

        </ContentWrapper>

      </Container >
    </>

  );
};

export default Grid;
