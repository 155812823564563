import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { ImArrowLeft, ImArrowRight } from "react-icons/im";


const Geral = styled.div`
  width: 99%;
  border: 1px solid #ccc;
  padding: 5px;
`;
const Table = styled.table`
  width: 99%;
  background-color: #fff;
  padding: 20px 20px;
  box-shadow: 0px 0px 2px #ccc;
  border-radius: 0.5px;
  margin: 5px auto;

`;

export const TdButton = styled.td`
  font-size: 14px;
  font-weight: bold;
  word-break: break-word;
  padding-top: 15px;
  padding-bottom: 5px;
  cursor: pointer;
`;

export const Thead = styled.thead``;

export const Tbody = styled.tbody`
  padding-bottom: 25px; 
`;

export const Tr = styled.tr`
  text-align: center;
  text-decoration: none;
  border: none;
  width: 100%;
  background-color: #fff;
  cursor: pointer;
  transition: transform 0.3s;

  &:hover {
    color: black;
    background-color: #f5f5f5;
  }
}

`;

export const Th = styled.th`
  color: #4f4f4f;
  text-align: start;
  border-bottom: inset;
  padding-bottom: 5px;
  padding: 5px;

`;

export const Td = styled.td`
  word-break: break-word;
  padding-top: 15px;
  padding-bottom: 5px;
  text-align: ${(props) => (props.alignCenter ? "center" : "start")};
  width: ${(props) => (props.width ? props.width : "auto")};
 

`;
export const Td2 = styled.td`
  word-break: break-word;
  padding-top: 15px;
  padding-bottom: 5px;
  align-items: center;

  &:hover {
    color: black;
    background-color: #f5f5f5;
  }

`;



export const Td3 = styled.td`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  span{
    margin: 0px 5px;
  }

`;

const Input = styled.input`
  width: 345px;
  padding: 0 10px;
  border: 1px solid #bbb;
  border-radius: 1px;
  height: 37px;


`;

const Button = styled.button`
  cursor: pointer;
  border: none;
  color: black;
  background-color: transparent;
  transition: transform 0.1s;

  &:hover {
    transform: scale(1.3);
  }
`;

const Div = styled.div`
display: flex;
align-items: center;

  
  span{
    padding: 0px  10px;
  }
`;


export const Tr1 = styled.tr`
  text-align: center;
  text-decoration: none;
  border: none;
  width: 100%;
  background-color: #fff;



}

`;


const Grid = ({  cursosCS, modalCursosCS }) => {



  const [usuariosFiltrados, setUsuariosFiltrados] = useState([]);
  const [codigoSelecionado, setCodigoSelecionado] = useState(null);
  const [listaVisivel, setListaVisivel] = useState({});
  const [feitosVisivel, setFeitosVisivel] = useState({});
  const [naoFeitosVisivel, setNaoFeitosVisivel] = useState({});

  // Agrupando os cursos em um novo formato
  const cursosAgrupados = cursosCS.map((curso) => ({
    codigo: curso[6],
    descricao: curso[7],
    renovacao: curso[9],
    usuarios: curso, // Para acesso posterior aos dados do usuário
  }));

  // Remover duplicatas usando filter
  const cursosUnicos = cursosAgrupados.filter((curso, index, self) =>
    index === self.findIndex((t) => t.codigo === curso.codigo)
  );


  const handleUsuarioClick = (codigo) => {
    modalCursosCS(codigo);
    /*
    const usuariosFiltrados = cursosCS.filter((curso) => curso[6] === codigo);

    const usuariosClassif = usuariosFiltrados.sort((a, b) => Number(a[0]) - Number(b[0]));


    setUsuariosFiltrados(usuariosClassif);
    setCodigoSelecionado(codigo);

    setListaVisivel((prev) => ({
      ...prev,
      [codigo]: !prev[codigo], // Inverte o estado de visibilidade dos "Não feitos"
      
    }));
    */
  };

  // Função para manipular clique em "Feitos"
  const handleFeitosClick = (codigo) => {
    const usuariosFiltrados = cursosCS.filter((curso) => curso[6] === codigo);
    const usuariosClassif = usuariosFiltrados.sort((a, b) => Number(a[0]) - Number(b[0]));

    setUsuariosFiltrados(usuariosClassif);
    setCodigoSelecionado(codigo);

    setFeitosVisivel((prev) => ({
      ...prev,
      [codigo]: !prev[codigo], // Inverte o estado de visibilidade dos "Feitos"
    }));
  };

  // Função para manipular clique em "Não Feitos"
  const handleNaoFeitosClick = (codigo) => {
    const usuariosFiltrados = cursosCS.filter((curso) => curso[6] === codigo);
    const usuariosClassif = usuariosFiltrados.sort((a, b) => Number(a[0]) - Number(b[0]));

    setUsuariosFiltrados(usuariosClassif);
    setCodigoSelecionado(codigo);

    setNaoFeitosVisivel((prev) => ({
      ...prev,
      [codigo]: !prev[codigo], // Inverte o estado de visibilidade dos "Não Feitos"
    }));
  };








  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;






  const handleSearch = async (term) => {
    setIsLoading(true);

    try {
      const filtered = cursosUnicos.filter((curso) => {
        const columnsToSearch = ['descricao'];

        // Verifica se o valor da coluna contém o termo de pesquisa
        return columnsToSearch.some((column) => {
          const columnValue = curso[column] ? curso[column].toLowerCase() : '';
          return columnValue.includes(term.toLowerCase());
        });
      });

      setFilteredUsers2(filtered);
    } catch (error) {
      console.error("Erro ao pesquisar:", error);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    handleSearch(searchTerm);
  }, [searchTerm]);




  const [filteredUsers2, setFilteredUsers2] = useState(cursosUnicos); // Inicializando como array vazio

  // Paginação
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  // Verifica se `filteredUsers2` é um array e faz o slice
  const currentItems2 = Array.isArray(filteredUsers2) ? filteredUsers2.slice(indexOfFirstItem, indexOfLastItem) : [];

  // Verifica se `filteredUsers2` existe e tem length, caso contrário assume 0
  const totalPages = filteredUsers2 && Array.isArray(filteredUsers2) ? Math.ceil(filteredUsers2.length / itemsPerPage) : 1;

  // Função para ir para a próxima página
  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  // Função para ir para a página anterior
  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };




  return (

    <Geral>

      <Td3 colSpan="7">
        <Input
          type="text"
          placeholder="Pesquisar... Ex: NR 20"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />

        <Div>
          <div>
            <Button onClick={handlePrevPage} disabled={currentPage === 1}><ImArrowLeft size={20} /></Button>
            <span>Página <strong>{currentPage}</strong> de <strong>{totalPages}</strong></span>
            <Button onClick={handleNextPage} disabled={currentPage === totalPages}> <ImArrowRight size={20} /></Button>
          </div>
        </Div>
      </Td3>
      <Table>
        <Thead>
          <Tr>
            <Th>Código:</Th>
            <Th>Nome do curso:</Th>
            <Th></Th>

          </Tr>
        </Thead>
        <Tbody >
          {currentItems2

            .map((curso, idx) => (
              <React.Fragment key={idx} >
                <Tr
                  onClick={() => handleUsuarioClick(curso.codigo)}
                  style={{
                    backgroundColor: listaVisivel[curso.codigo] ? '#d3d3d3' : 'transparent'
                  }}
                >
                  <Td>{curso.codigo}</Td>
                  <Td>{curso.descricao}</Td>
                  <Td>{curso.renovacao}</Td>
                </Tr>

                {/* Subtabela para mostrar usuários se a lista estiver visível */}
                {listaVisivel[curso.codigo] && (
                  <>
                    {/* Linha "Não Feitos" */}
                    <Tr
                      onClick={() => handleNaoFeitosClick(curso.codigo)}
                      style={{
                        backgroundColor: naoFeitosVisivel[curso.codigo] ? '#d3d3d3' : 'transparent',
                      }}
                    >
                      <Td2 colSpan={3} style={{ cursor: 'pointer', fontWeight: 'bold' }}>
                        Status: Não Feitos
                      </Td2>
                    </Tr>

                    {/* Subtabela para mostrar "Não Feitos" */}
                    {naoFeitosVisivel[curso.codigo] && (
                      <Tr1>
                        <Td colSpan={3}>
                          <Table>
                            <Thead>
                              <Tr1>
                                <Th>Crachá</Th>
                                <Th>Nome</Th>
                                <Th>Função</Th>
                                <Th>Turno</Th>
                                <Th>Status</Th>
                                <Th>Obser.</Th>
                              </Tr1>
                            </Thead>
                            <Tbody>
                              {usuariosFiltrados
                                .filter((usuario) => usuario[11] === "Não feito")
                                .map((usuario, index) => (
                                  <Tr1 key={index}>
                                    <Td>{usuario[0]}</Td>
                                    <Td>{usuario[1]}</Td>
                                    <Td>{usuario[3]}</Td>
                                    <Td>{usuario[5]}</Td>
                                    <Td2>{usuario[11]}</Td2>
                                    <Td2>{usuario[12]}</Td2>
                                  </Tr1>
                                ))}
                            </Tbody>
                          </Table>
                        </Td>
                      </Tr1>
                    )}

                    {/* Linha "Feitos" */}
                    <Tr
                      onClick={() => handleFeitosClick(curso.codigo)}
                      style={{
                        backgroundColor: feitosVisivel[curso.codigo] ? '#d3d3d3' : 'transparent',
                      }}
                    >
                      <Td2 colSpan={3} style={{ cursor: 'pointer', fontWeight: 'bold' }}>
                        Status: Feitos
                      </Td2>
                    </Tr>

                    {/* Subtabela para mostrar "Feitos" */}
                    {feitosVisivel[curso.codigo] && (
                      <Tr1>
                        <Td colSpan={3}>
                          <Table>
                            <Thead>
                              <Tr1>
                                <Th>Crachá</Th>
                                <Th>Nome</Th>
                                <Th>Função</Th>
                                <Th>Turno</Th>
                                <Th>Status</Th>
                                <Th>Obser.</Th>
                              </Tr1>
                            </Thead>
                            <Tbody>
                              {usuariosFiltrados
                                .filter((usuario) => usuario[11] === "Feito")
                                .map((usuario, index) => (
                                  <Tr1 key={index}>
                                    <Td>{usuario[0]}</Td>
                                    <Td>{usuario[1]}</Td>
                                    <Td>{usuario[3]}</Td>
                                    <Td>{usuario[5]}</Td>
                                    <Td2>{usuario[11]}</Td2>
                                    <Td2>{usuario[12]}</Td2>
                                  </Tr1>
                                ))}
                            </Tbody>
                          </Table>
                        </Td>
                      </Tr1>
                    )}
                  </>
                )}
              </React.Fragment>

            ))}
        </Tbody>
      </Table>
    </Geral >
  );
};


export default Grid;
