import React, { useState} from "react";

import GridCursosCS from './Grid-cursos-cs.js';
import GridCargosRepitidos from './Grid-cargos-repitidos.js';
import GridAnexo from './Grid-anexo.js';
import ModalCursosCS from './Modal-cursos-cs.js';
import ModalCargosRepetidos from './Modal-cargos-repitidos.js';
import ModalAnexo from './Modal-anexo.js';
import CursosAnexo from './Grid-cursos-anexo.js';
import ModalCursosAnexo from './Modal-cursos-anexo.js';

import GlobalStyle from "../../../styles/global.js";
import styled from "styled-components";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import { IoClose } from "react-icons/io5";




const Container = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: center;
  box-sizing: border-box;
    padding-top: 5px;
`;


const Div = styled.div`
  width: 80%;
  display: flex;
  align-items: center;
  margin: 0px 0px 0px 0px;
  justify-content: center;
  
  span{
    padding: 0px  10px;
  }
`;

const Div2 = styled.div`

`;


const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  
`;

const ModalContent = styled.div`
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  max-height: 90vh; 
  overflow-y: auto; 

`;

const ButtonClose = styled.button`
  cursor: pointer;
  border: none;
  background-color: #f5f5f5;
  transition: transform 1.5s;
  &:hover {
    transform: scale(1.5);
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: start; 
`;

const DivCenter = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  span{
    padding: 0px  10px;
  }
`;

const ButtonFiltro = styled.button`
  
  padding: 5px 20px;
  cursor: pointer;
  border-radius: 15px 15px 0px 0px;
  border: none;
  background-color: #ccc;
  color: white;
  height: 45px;
  font-weight: bold;
  font-size: 16px;
  margin-left: 5px;
  transition: transform 0.3s;
  &:hover {
    transform: scale(1.01);
    background-color: gray;
  }
`;




function Usuarios({ exata_CS, anexoCS, cursosCS, cursosCargoCS, cargosRepitidosCS }) {




  //Abre o modal 'CursosCS'
  const [modalCursosCSCodigo, setModalCursosCSCodigo] = useState('');
  const [isModalOpentoggleModalCursosCS, setIsModalOpenModalCursosCS] = useState(false);
  const modalCursosCS = (codigo) => {
    setIsModalOpenModalCursosCS(!isModalOpentoggleModalCursosCS);
    setModalCursosCSCodigo(codigo)
  };

  //Abre o modal 'CursosCS'
  const [modalCargosRepetidosCodigo, setModalCargosRepetidosCodigo] = useState('');
  const [isModalOpentoggleModalCargosRepetidos, setIsModalOpenModalCargosRepetidos] = useState(false);
  const modalCargosRepetidos = (dept) => {
    setIsModalOpenModalCargosRepetidos(!isModalOpentoggleModalCargosRepetidos);
    setModalCargosRepetidosCodigo(dept)
  };

  //Abre o modal 'Anexo'
  const [modalAnexoCodigo, setModalAnexoCodigo] = useState('');
  const [isModalOpentoggleModalAnexo, setIsModalOpenModalAnexo] = useState(false);
  const modalAnexo = (userId) => {
    setIsModalOpenModalAnexo(!isModalOpentoggleModalAnexo);
    setModalAnexoCodigo(userId)
  };

  //Abre o modal 'Anexo'
  const [modalCursosAnexoCodigo, setModalCursosAnexoCodigo] = useState('');
  const [isModalOpentoggleModalCursosAnexo, setIsModalOpenModalCursosAnexo] = useState(false);
  const modalCursosAnexo = (userId) => {
    setIsModalOpenModalCursosAnexo(!isModalOpentoggleModalCursosAnexo);
    setModalCursosAnexoCodigo(userId)
  };










  //Controla o GridGeral e GridMensal
  const [abaAtiva, setAbaAtiva] = useState('cursos-cs'); // Estado para controlar a aba ativa

  // Função para trocar de aba
  const handleClick = (aba) => {
    setAbaAtiva(aba);
  };




  return (
    <>
      {isModalOpentoggleModalCursosCS && (
        <ModalContainer>
          <ModalContent>
            <ButtonContainer>
              <ButtonClose onClick={() => {
                modalCursosCS();
              }}>
                <IoClose color={"red"} size={25} />
              </ButtonClose>
            </ButtonContainer>
            <ModalCursosCS cursosCS={cursosCS} modalCursosCSCodigo={modalCursosCSCodigo} />
          </ModalContent>
        </ModalContainer>
      )}

      {isModalOpentoggleModalCargosRepetidos && (
        <ModalContainer>
          <ModalContent>
            <ButtonContainer>
              <ButtonClose onClick={() => {
                modalCargosRepetidos();
              }}>
                <IoClose color={"red"} size={25} />
              </ButtonClose>
            </ButtonContainer>
            <ModalCargosRepetidos cursosCargoCS={cursosCargoCS} cargosRepitidosCS={cargosRepitidosCS} modalCargosRepetidosCodigo={modalCargosRepetidosCodigo} />
          </ModalContent>
        </ModalContainer>
      )}

      {isModalOpentoggleModalAnexo && (
        <ModalContainer>
          <ModalContent>
            <ButtonContainer>
              <ButtonClose onClick={() => {
                modalAnexo();
              }}>
                <IoClose color={"red"} size={25} />
              </ButtonClose>
            </ButtonContainer>
            <ModalAnexo exata_CS={exata_CS} anexoCS={anexoCS} modalAnexoCodigo={modalAnexoCodigo} />
          </ModalContent>
        </ModalContainer>
      )}

      {isModalOpentoggleModalCursosAnexo && (
        <ModalContainer>
          <ModalContent>
            <ButtonContainer>
              <ButtonClose onClick={() => {
                modalCursosAnexo();
              }}>
                <IoClose color={"red"} size={25} />
              </ButtonClose>
            </ButtonContainer>
            <ModalCursosAnexo exata_CS={exata_CS} anexoCS={anexoCS} modalCursosAnexoCodigo={modalCursosAnexoCodigo} cursosCS={cursosCS} />
          </ModalContent>
        </ModalContainer>
      )}

      <div >

        <Container>

          <DivCenter>
            {/* Botões para trocar de aba */}
            <Div>
              <Div2>
                
                <ButtonFiltro onClick={() => handleClick('cursos-cs')}>
                  Cursos Obrigatórios
                </ButtonFiltro>
                <ButtonFiltro onClick={() => handleClick('cargos-repitidos-cs')}>
                  Cargos Repetidos
                </ButtonFiltro>
                <ButtonFiltro onClick={() => handleClick('certificados-cs')}>
                  Anexos
                </ButtonFiltro>
                <ButtonFiltro onClick={() => handleClick('cursos-anexo-cs')}>
                  Cursos Obrigatórios x Anexos
                </ButtonFiltro>
              </Div2>
            </Div>


            {abaAtiva === 'cursos-cs' ? (
              <GridCursosCS
                cursosCS={cursosCS}
                modalCursosCS={modalCursosCS}
              />
            ) : abaAtiva === 'cargos-repitidos-cs' ? (
              <GridCargosRepitidos
                modalCargosRepetidos={modalCargosRepetidos}
                cargosRepitidosCS={cargosRepitidosCS}
                cursosCargoCS={cursosCargoCS}
              />
            ) : abaAtiva === 'certificados-cs' ? (
              <GridAnexo
                anexoCS={anexoCS}
                exata_CS={exata_CS}
                modalAnexo={modalAnexo}
              />
            ) : abaAtiva === 'cursos-anexo-cs' ? (
              <CursosAnexo
                anexoCS={anexoCS}
                exata_CS={exata_CS}
                modalCursosAnexo={modalCursosAnexo}
                cursosCS={cursosCS}
              />
            ) : null}
          </DivCenter>
        </Container>


        <Div>
          <ToastContainer autoClose={2500} position={toast.POSITION.BOTTOM_LEFT} style={{ width: '400px', fontSize: '22px' }} />
          <GlobalStyle />
        </Div>

      </div>
    </>
  );
}

export default Usuarios;
