import React from "react";
import styled from "styled-components";

const Geral = styled.div`
  width: 1000px;
  border: 1px solid #ccc;
  padding: 20px;
  background-color: #fff;
  display: flex;
  align-items: center;
  flex-direction: column;
  
`;
const Table = styled.table`
  width: 980px;
  background-color: #fff;
  padding: 20px 20px;
  box-shadow: 0px 0px 2px #ccc;
  border-radius: 0.5px;
  margin: 5px auto;

`;

export const TdButton = styled.td`
  font-size: 14px;
  font-weight: bold;
  word-break: break-word;
  padding-top: 15px;
  padding-bottom: 5px;
  cursor: pointer;
`;

export const Thead = styled.thead``;

export const Tbody = styled.tbody`
  padding-bottom: 25px; 
`;

export const Tr = styled.tr`
  text-align: center;


`;

export const Th = styled.th`
  color: #4f4f4f;
  text-align: start;
  border-bottom: inset;
  padding-bottom: 5px;


`;

export const Td = styled.td`
  word-break: break-word;
  padding-top: 15px;
  padding-bottom: 5px;
  text-align: ${(props) => (props.alignCenter ? "center" : "start")};
  width: ${(props) => (props.width ? props.width : "auto")};
 

`;

export const Td1 = styled.td`
  padding-top: 15px;
  text-align: center;


`;

export const Td2 = styled.td`
  word-break: break-word;
  padding-top: 15px;
  padding-bottom: 5px;
  text-align: ${(props) => (props.alignCenter ? "center" : "start")};
  width: ${(props) => (props.width ? props.width : "auto")};
 
`;

export const Td3 = styled.td`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  span{
    margin: 0px 5px;
  }

`;



const Div = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  margin-top: 25px;

  span{
    padding: 0px  10px;
  }
`;



const Grid = ({ onEditTreinamentoPorUsuario, usuariosExata_Treinamento }) => {


  const usuarioFiltrado = usuariosExata_Treinamento.filter(usuario => usuario.cracha == onEditTreinamentoPorUsuario.cracha);



  return (

    <Geral>

      <Div>
        <h3>{onEditTreinamentoPorUsuario.cracha} - {onEditTreinamentoPorUsuario.nome}</h3>
        <Table >

          <Thead>
            <Tr>
              <Th>Treinamentos/Eventos:</Th>
              <Th>Data:</Th>
              <Th>Status:</Th>
            </Tr>
          </Thead>
          <Tbody>
            {usuarioFiltrado
              .map((item, i) => (
                <Tr key={i}>
                  <Td>{item.titulo}</Td> {/* Titulo */}
                  <Td>{item.data}</Td> {/* Data */}
                  <Td>
                    {item.status === 'I' || item.status === 'P' ? 'Presente' : 'Faltante'}
                  </Td>
                </Tr>
              ))}
          </Tbody>


        </Table>

      </Div>


    </Geral>
  );
};


export default Grid;
