import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { ImArrowLeft, ImArrowRight } from "react-icons/im";

const Geral = styled.div`
  width: 99%;
  border: 1px solid #ccc;
  padding: 5px;
`;
const Table = styled.table`
  width: 99%;
  background-color: #fff;
  padding: 20px 20px;
  box-shadow: 0px 0px 2px #ccc;
  border-radius: 0.5px;
  margin: 5px auto;

`;

export const TdButton = styled.td`
  font-size: 14px;
  font-weight: bold;
  word-break: break-word;
  padding-top: 15px;
  padding-bottom: 5px;
  cursor: pointer;
`;

export const Thead = styled.thead``;

export const Tbody = styled.tbody`
  padding-bottom: 25px; 
`;

export const Tr = styled.tr`
  text-align: center;
  text-decoration: none;
  border: none;
  width: 100%;
  background-color: #fff;
  cursor: pointer;
  transition: transform 0.3s;

  &:hover {
    color: black;
    background-color: #f5f5f5;
  }
}

`;

export const Th = styled.th`
  color: #4f4f4f;
  text-align: start;
  border-bottom: inset;
  padding-bottom: 5px;


`;

export const Td = styled.td`
  word-break: break-word;
  padding-top: 15px;
  padding-bottom: 5px;
  text-align: ${(props) => (props.alignCenter ? "center" : "start")};
  width: ${(props) => (props.width ? props.width : "auto")};
 

`;

export const Td1 = styled.td`
  padding-top: 15px;
  text-align: center;


`;

export const Td2 = styled.td`
  word-break: break-word;
  padding-top: 15px;
  padding-bottom: 5px;
  text-align: ${(props) => (props.alignCenter ? "center" : "start")};
  width: ${(props) => (props.width ? props.width : "auto")};
 
`;

export const Td3 = styled.td`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  span{
    margin: 0px 5px;
  }

`;

const Input = styled.input`
  width: 345px;
  padding: 0 10px;
  border: 1px solid #bbb;
  border-radius: 1px;
  height: 37px;


`;

const Button = styled.button`
  
  cursor: pointer;
  border: none;

  color: black;

  transition: transform 0.1s;
  &:hover {
    transform: scale(1.3);
  }
`;

const Div = styled.div`
display: flex;
align-items: center;

  
  span{
    padding: 0px  10px;
  }
`;



const Grid = ({ setOnEditTreinamentoPorUsuario, toggleTreinamentoPorUsuario, users }) => {

  const [searchTerm, setSearchTerm] = useState('');
  const [searchTermSetor, setSearchTermSetor] = useState('');
  const [filteredUsers, setFilteredUsers] = useState(users);
  const [isLoading, setIsLoading] = useState(true);





  //Input de pesquisa
  const handleSearch = async (term) => {
    setIsLoading(true);

    try {
      const filtered = users.filter((user) => {
        const nome = user.nome ? user.nome.toLowerCase() : ''; // Verifica se user.logon é null ou undefined

        return (
          nome.includes(term.toLowerCase())
        );
      });
      setFilteredUsers(filtered);
    } catch (error) {
      console.error("Erro ao pesquisar:", error);
    }

    setIsLoading(false);
    //setSearchTerm('');
  };

  useEffect(() => {

    handleSearch(searchTerm);
  }, [searchTerm, users]);

  const handleSearchSetor = async (term) => {
    setIsLoading(true);

    try {
      const filtered = users.filter((user) => {
        const departamento = user.departamento ? user.departamento.toLowerCase() : ''; // Verifica se user.logon é null ou undefined

        return (
          departamento.includes(term.toLowerCase())
        );
      });
      setFilteredUsers(filtered);
    } catch (error) {
      console.error("Erro ao pesquisar:", error);
    }

    setIsLoading(false);
    //setSearchTerm('');
  };

  useEffect(() => {

    handleSearchSetor(searchTermSetor);
  }, [searchTermSetor, users]);





  //Paginação
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 50; // Defina a quantidade de itens por página conforme necessário
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredUsers.slice(indexOfFirstItem, indexOfLastItem);


  const totalPages = Math.ceil(filteredUsers.length / itemsPerPage);
  // Função para ir para a próxima página
  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };
  // Função para ir para a página anterior
  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };


  const handleEdit = (item) => {
    setOnEditTreinamentoPorUsuario(item);
    toggleTreinamentoPorUsuario();
  };



  return (

    <Geral>
      <Td3 colSpan="7">
        <Input
          type="text"
          placeholder="Pesquisar nome"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <Input
          type="text"
          placeholder="Pesquisar setor"
          value={searchTermSetor}
          onChange={(e) => setSearchTermSetor(e.target.value)}
        />

        <Div>
          <div>
            <Button onClick={handlePrevPage} disabled={currentPage === 1}><ImArrowLeft size={20} /></Button>
            <span>Página <strong>{currentPage}</strong> de <strong>{totalPages}</strong></span>
            <Button onClick={handleNextPage} disabled={currentPage === totalPages}> <ImArrowRight size={20} /></Button>
          </div>
        </Div>
      </Td3>
      <Table >

        <Thead>
          <Tr>
            <Th>Crachá:</Th>
            <Th>Nome:</Th>
            <Th>Setor:</Th>
          </Tr>
        </Thead>
        <Tbody>
          {currentItems.map((item, i) => (
            <Tr key={i} onClick={() => handleEdit(item)}>
              <Td>{item.cracha}</Td> {/* Título do treinamento */}
              <Td>{item.nome}</Td> {/* Data do treinamento */}
              <Td>{item.departamento}</Td>
            </Tr>
          ))}
        </Tbody>

      </Table>

    </Geral>
  );
};


export default Grid;
