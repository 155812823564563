import { useState, useEffect } from 'react';
import axios from 'axios';

//const url = "http://192.168.100.103:8850/api";
const url = "/api";
const token = localStorage.getItem('token');


export const useQuadroHook = () => {
    const [quadro, setQuadro] = useState([]);


    const fetchQuadro = async () => {
        try {
            const res = await axios.get(`${url}/quadro`, { headers: { Authorization: `Bearer ${token}` } });
            setQuadro(res.data);
        } catch (error) {
            console.error('Erro ao buscar dados de quadro:', error);
        }
    };


    useEffect(() => {
        fetchQuadro();
    }, [token]);

    
    return { quadro, fetchQuadro };
};
