import { useState, useEffect } from 'react';
import axios from 'axios';

//const url = "http://192.168.100.103:8850/api";
const url = "/api";
const token = localStorage.getItem('token');

export const useModuloHook = () => {
    const [modulo, setModulo] = useState([]);

    const fetchModulo = async () => {
        try {
            const res = await axios.get(`${url}/modulo`, { headers: { Authorization: `Bearer ${token}` } });
            setModulo(res.data);
        } catch (error) {
            console.error('Erro ao buscar dados de modulo:', error);
        }
    };

    useEffect(() => {
        fetchModulo();
    }, [token]);

    return { modulo, fetchModulo };
};
