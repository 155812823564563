import React, { useState } from 'react';

import styled from "styled-components";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";





const FormContainer = styled.form`
  width: 100%;
  max-width: 440px;
  background-color: rgba(255, 255, 255, 0.8);
  justify-content: center; 
  display: flex; 
  padding: 5px 10px;
  box-shadow: 0px 0px 2px #ccc;
  border-radius: 0px 0px 10px 10px;
  align-items: center;
  gap: 5px;
  flex-wrap: wrap;
  

  @media (max-width: 1360px) {
    max-width: 440px;
    justify-content: space-between; 
  }
  @media (max-width: 1024px) {
    width: 440px;
    justify-content: space-between; 
  }
  @media (max-width: 640px) {
    width: 380px;
    justify-content: space-between; 
  }
  @media (max-width: 480px) {

    width: 340px;
    justify-content: space-between; 
  }
  @media (max-width: 376px) {
    width: 320px;
  }
`;




const Title2 = styled.h2`
  color: #4f4f4f;
  padding-top: 2vw;
  display: flex;
  justify-content: center; 
  align-items: center;
  flex-direction: column;
  
  h4 {

    font-weight: bold;
    font-size: 37px;
    text-align: center;

  }

  @media (max-width: 1360px) {
    font-size: 22px;
    padding-left: 15%;
  }
 
  @media (max-width: 640px) {
    font-size: 22px;
    padding-left: 10%;
  }
  @media (max-width: 480px) {
    font-size: 22px;
    padding-top: 10%;
  }
  @media (max-width: 376px) {
    font-size: 20px;
    display: flex;
    padding-left: 10%;

  }

`;



const Card = styled.div`
  width: 360px;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  text-align: center;

  @media (max-width: 480px) {
    width: 300px;
  }
`;



const Title = styled.h1`
  font-size: 34px;
  color: black;
  margin-bottom: 20px;
  font-family: "Open Sans", sans-serif;
`;

const Input = styled.input`
  font-family: "Open Sans", sans-serif;
  width: 94%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  &:focus {
    outline: none;
    border-color: #076633;
  }
`;

const Button = styled.button`
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  background-color: #076633;
  color: white;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #054421;
  }
`;

const ForgotPassword = styled.p`
  font-size: 14px;
  color: #333;
  margin-top: 10px;
  cursor: pointer;
  text-decoration: underline;
  &:hover {
    color: #076633;
  }
`;






function Login({ urlApi, modalNovaSenha }) {
  const [login, setLogin] = useState('');
  const [senha, setSenha] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Função de login

  const handleLogin = async () => {
    if (!login || !senha) {
      setErrorMessage('Por favor, preencha todos os campos.');
      return;
    }
    setIsSubmitting(true); // Bloqueia múltiplos cliques
    try {

      const response = await fetch(`${urlApi}/login/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          login: login.toLowerCase(),
          senha,
        }),
      });



      const data = await response.json();
      const tokenUsuario = data.token;
      const id = data.id_usuario;
      const usuario = data.login;


      if (response.ok) {
        localStorage.setItem('token', tokenUsuario);
        localStorage.setItem('id', id);
        localStorage.setItem('usuario', usuario);



        toast.success(data.mensagem);

        // Redefinir o formulário
        const form = document.querySelector('form');
        if (form) {
          form.reset();
        }

        // Salvar log de sucesso no banco de dados
        await saveLoginLog(login, 'Login realizado com sucesso!');

        setTimeout(function () {
          window.location.href = "/";
        }, 2000);

      } else {
        toast.error(data.error || 'Erro desconhecido no login.');

        // Salvar log de falha no banco de dados
        await saveLoginLog(login, `Falha no login: ${data.error || 'Erro desconhecido'}`);
      }
    } catch (error) {
      alert('Erro de rede ou erro desconhecido.');

      // Salvar log de falha de rede no banco de dados
      await saveLoginLog(login, 'Erro de rede ou erro desconhecido');
    } finally {
      setIsSubmitting(false); // Reativa o botão
    }
  };

  // Passa o logs para serem salvos no banco
  const saveLoginLog = async (login, descricao) => {
    try {
      const logData = {
        login,
        descricao,
      };

      const response = await fetch(`${urlApi}/log`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify(logData),
      });

      if (!response.ok) {
        throw new Error('Failed to save log');
      }
    } catch (error) {
      console.error('Erro ao salvar log:', error);
    }
  };




  // Chamar a função com enter
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Previne o comportamento padrão do Enter (submeter o formulário)
      handleLogin();
    }
  };



  const novaSenha = () => {
    modalNovaSenha();
  };


  return (
    <FormContainer onKeyDown={handleKeyDown}>
      <Title2>
        <Title>Portal do Colaborador</Title>

      </Title2>
      <Card>
        <form>

          <Input
            type="text"
            placeholder="Digite seu login"
            value={login}
            onChange={(e) => setLogin(e.target.value)}
          />

          <Input
            type="password"
            placeholder="Digite sua senha"
            value={senha}
            onChange={(e) => setSenha(e.target.value)}
          />

          <Button type="button" onClick={handleLogin} disabled={isSubmitting}>
            {isSubmitting ? "Entrando..." : "Entrar"}
          </Button>
        </form>
        <ForgotPassword type="button" onClick={novaSenha}>
          Esqueci minha senha
        </ForgotPassword>

      </Card>
    </FormContainer>
  );
}

export default Login;

