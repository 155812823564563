import React, { useState } from "react";

import GridTreinamento from "./Grid-treinamento.js";
import GridUsuarios from "./Grid-usuarios.js";
import GridGeral from "./Grid-geral.js";
import ParticipantesPorCurso from "./ParticipantesPorCurso.js";
import TreinamentoPorUsuario from "./TreinamentoPorUsuarios.js";

import GlobalStyle from "../../../styles/global.js";
import styled from "styled-components";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import { IoClose } from "react-icons/io5";


const Container = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: center;
  box-sizing: border-box;
  padding-top: 5px;
`;


const Div = styled.div`
  width: 80%;
  display: flex;
  align-items: center;
  margin: 0px 0px 0px 0px;
  justify-content: center;
  
  span{
    padding: 0px  10px;
  }
`;

const Div2 = styled.div`

`;


const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  
`;

const ModalContent = styled.div`
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  max-height: 90vh; 
  overflow-y: auto; 
`;

const ButtonClose = styled.button`
  cursor: pointer;
  border: none;
  background-color: #f5f5f5;
  transition: transform 1.5s;
  &:hover {
    transform: scale(1.5);
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: start; 
`;

const DivCenter = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  span{
    padding: 0px  10px;
  }
`;

const ButtonFiltro = styled.button`
  
  padding: 5px 20px;
  cursor: pointer;
  border-radius: 15px 15px 0px 0px;
  border: none;
  background-color: #ccc;
  color: white;
  height: 45px;
  font-weight: bold;
  font-size: 16px;
  margin-left: 5px;
  transition: transform 0.3s;
  &:hover {
    transform: scale(1.01);
    background-color: gray;
  }
`;



function Usuarios({ urlApi, usuariosExata_Treinamento, exata_CS, treinamentosEXATA, users }) {


  const [onEdit, setOnEdit] = useState(null);




  //Abre o modal 'Participantes por curso'
  const [isModalOpentoggleModalParticipatesPorCurso, setIsModalOpentoggleModalParticipatesPorCurso] = useState(false);
  const toggleModalParticipatesPorCurso = () => {
    setIsModalOpentoggleModalParticipatesPorCurso(!isModalOpentoggleModalParticipatesPorCurso);

  };

  //Abre o modal 'Treinamentos por usuario'
  const [onEditTreinamentoPorUsuario, setOnEditTreinamentoPorUsuario] = useState([]);
  const [isModalOpentoggleTreinamentoPorUsuario, setIsModalOpentoggleTreinamentoPorUsuario] = useState(false);
  const toggleTreinamentoPorUsuario = () => {
    setIsModalOpentoggleTreinamentoPorUsuario(!isModalOpentoggleTreinamentoPorUsuario);

  };













  //Controla o GridGeral e GridMensal
  const [abaAtiva, setAbaAtiva] = useState('geral'); // Estado para controlar a aba ativa

  // Função para trocar de aba
  const handleClick = (aba) => {
    setAbaAtiva(aba);
  };


  return (
    <>



      {isModalOpentoggleModalParticipatesPorCurso && (
        <ModalContainer>
          <ModalContent>
            <ButtonContainer>
              <ButtonClose onClick={() => {
                toggleModalParticipatesPorCurso();
              }}>
                <IoClose color={"red"} size={25} />
              </ButtonClose>
            </ButtonContainer>
            <ParticipantesPorCurso onEdit={onEdit} urlApi={urlApi} />
          </ModalContent>
        </ModalContainer>
      )}


      {isModalOpentoggleTreinamentoPorUsuario && (
        <ModalContainer>
          <ModalContent>
            <ButtonContainer>
              <ButtonClose onClick={() => {
                toggleTreinamentoPorUsuario();
              }}>
                <IoClose color={"red"} size={25} />
              </ButtonClose>
            </ButtonContainer>
            <TreinamentoPorUsuario onEditTreinamentoPorUsuario={onEditTreinamentoPorUsuario} usuariosExata_Treinamento={usuariosExata_Treinamento} />
          </ModalContent>
        </ModalContainer>
      )}


      <div >


        <Container>

          <DivCenter>

            <Div>
              <Div2>
                
                <ButtonFiltro onClick={() => handleClick('geral')}>
                  Departamento
                </ButtonFiltro>
                <ButtonFiltro onClick={() => handleClick('usuarios')}>
                  Usuários
                </ButtonFiltro>
                <ButtonFiltro onClick={() => handleClick('treinamento')}>
                  Treinamento
                </ButtonFiltro>
              </Div2>
            </Div>


            {abaAtiva === 'geral' ? (
              <GridGeral
                setOnEditTreinamentoPorUsuario={setOnEditTreinamentoPorUsuario}
                urlApi={urlApi}
                toggleTreinamentoPorUsuario={toggleTreinamentoPorUsuario}
                exata_CS={exata_CS}
                treinamentosEXATA={treinamentosEXATA}
              />
            ) : abaAtiva === 'usuarios' ? (
              <GridUsuarios
                setOnEditTreinamentoPorUsuario={setOnEditTreinamentoPorUsuario}
                urlApi={urlApi}
                toggleTreinamentoPorUsuario={toggleTreinamentoPorUsuario}
                users={users}
              />
            ) : abaAtiva === 'treinamento' ? (
              <GridTreinamento
                setOnEdit={setOnEdit}
                urlApi={urlApi}
                toggleModalParticipatesPorCurso={toggleModalParticipatesPorCurso}
                treinamentosEXATA={treinamentosEXATA}
              />
            ) : null}
          </DivCenter>
        </Container>


        <Div>
          <ToastContainer autoClose={2500} position={toast.POSITION.BOTTOM_LEFT} style={{ width: '400px', fontSize: '22px' }} />
          <GlobalStyle />
        </Div>

      </div>
    </>
  );
}

export default Usuarios;
