import React, { useState, useEffect } from "react";
import axios from "axios"

import GridUsuarios from "./components/Grid-usuarios.js";
import { useUsuariosHook } from '../../../hook/getUsuarios.js';

import GlobalStyle from "../../../styles/global.js";
import styled from "styled-components";
import "react-toastify/dist/ReactToastify.css";
import logo from "../../../styles/imagem/Logomarca-06.png";
import { toast, ToastContainer } from "react-toastify";
import { FaArrowLeft } from "react-icons/fa";
import ModalVerUsuario from "./components/ModalVerUsuario.js";
import { IoClose } from "react-icons/io5";


const DivLayout = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: center;
  box-sizing: border-box;
`;

const Title = styled.nav`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 5.5rem;
  background-color: #076633;
`;

const Title2 = styled.h2`
  width: 100%;
  color: #fff;
  font-size: 28px;
  display: flex;
  justify-content: space-around; 
  align-items: center;

  img{

    padding-top: 1%;
  }  
`;


const Div = styled.div`
  position: fixed;
  top:0;
  left:0;
  display: flex;
  flex-direction: column;
`;
const Div2 = styled.div`
  display: flex;
  flex-direction: row;
  div{
    display: flex;
    justify-content: center; 
    margin-left: 25px;
    align-items: center;
  } 
`;
const Input = styled.input`
  width: 120px;
  padding: 0 10px;
  border: 1px solid #bbb;
  border-radius: 1px;
  height: 37px;

  
`;
const Label = styled.label`
  color: #4f4f4f;
  font-weight: bold;
  font-size: 17px;  
`;


const Filtro = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;


`;

const ButtonSide = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  cursor: pointer;
  border: none;
  background-color: #A6A29F;
  color: #fff;
  font-size: 20px;

`;

const ButtonSide1 = styled.button`
  cursor: pointer;
  border: none;
  background-color: #076633;
  color: #fff;
  font-size: 18px;

  &.toggle-btn {
    position: absolute;
    top: 2;
    left: 0;
    padding-left: 1vw;
  }

  &.button-exit{
    position: absolute;
    top: 2;
    right: 0;
    padding-right: 1vw;
  }

`;


const Button = styled.button`
  padding: 5px 20px;
  cursor: pointer;
  border-radius: 3px;
  border: none;
  width: auto;
  background-color: #076633;
  color: white;
  height: auto;
  font-weight: bold;
  font-size: 18px;
  margin: 5px 20px;
  transition: transform 0.3s;
  background: rgb(128, 128, 128); /* Fundo verde translúcido */
  border: 1px solid rgb(7, 102, 51); /* Borda verde translúcida */
  border-radius: 5px; /* Bordas arredondadas */
  backdrop-filter: blur(10px); /* Efeito de desfoque */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Sombra leve */
  transition: transform 0.1s, background 0.3s, border 0.3s;
  
  &:hover {
    transform: scale(1.1);
  }

`;

const ButtonClose = styled.button`
  position: fixed;
  cursor: pointer;
  border: none;
  background-color: #fff;
  transition: transform 0.1s;
  &:hover {
    transform: scale(1.5);
  }
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: start; 
`;
const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  max-height: 90vh; 
  overflow-y: scroll; 
  overflow-x: hidden;
`;
function Usuarios() {

  //const urlFront = "http://192.168.100.103:3050/gestao/";
  //const urlApi = "http://192.168.100.103:8850/api";
  //const urlLogin = "http://192.168.100.103:3050/";
  const urlFront = "/gestao/";
  const urlApi = "/api";
  const urlLogin = "/";

  const token = localStorage.getItem('token');
  const id = localStorage.getItem('id');

  const { usuarios } = useUsuariosHook();


  const [onEdit, setOnEdit] = useState(null);





  //Links
  const handleDashboard = () => {
    const url = urlFront;
    window.location.href = url;
  };



  //Verifica o token do usuário
  const [tToken, setToken] = useState("");
  const getToken = async () => {
    try {
      const res = await axios.get(`${urlApi}/login/token`, { headers: { Authorization: `Bearer ${token}` } });
      setToken(res.data);
    } catch (error) {
      if (error.response && (error.response.status === 400 || error.response.status === 403 || error.response.status === 401)) {
        window.location.href = `${urlFront}/login`;
      } else {
        console.error("Erro ao obter token:", error);
      }
    }
  };


  const hoje = new Date();
  const primeiroDiaAno = new Date(new Date().getFullYear(), 0, 1).toISOString().split('T')[0];
  const ultimoDia = new Date(hoje.getFullYear(), hoje.getMonth() + 1, 0).toISOString().split('T')[0];

  const [startDate, setStartDate] = useState(primeiroDiaAno);
  const [endDate, setEndDate] = useState(ultimoDia);
  //Get retorna os acessos
  const [tAcesso, setAcesso] = useState("");
  const getAcesso = async () => {
    try {
      const res = await axios.get(`${urlApi}/vwacesso/modulo2`, {
        headers: { Authorization: `Bearer ${token}` },
        params: { startDate, endDate },
      });
      setAcesso(res.data);
    } catch (error) {
      if (error.response && (error.response.status === 400 || error.response.status === 403 || error.response.status === 401)) {
        window.location.href = `${urlFront}/login`;
      } else {
        console.error("Erro ao obter token:", error);
      }
    }
  };

  //Get retorna os acessos
  const [tPagina, setPagina] = useState("");
  const getPagina = async () => {
    try {
      const res = await axios.get(`${urlApi}/vwacesso/pagina2`, {
        headers: { Authorization: `Bearer ${token}` },
        params: { startDate, endDate },
      });
      setPagina(res.data);
    } catch (error) {
      if (error.response && (error.response.status === 400 || error.response.status === 403 || error.response.status === 401)) {
        window.location.href = `${urlFront}/login`;
      } else {
        console.error("Erro ao obter token:", error);
      }
    }
  };




  //Get retorna os acessos
  const [tUsuario, setUsuario] = useState("");
  const [somandoUsuarios, setResultado] = useState([]);


  const getUsuario = async () => {
    try {
      const res = await axios.get(`${urlApi}/vwacesso/usuario2`, {
        headers: { Authorization: `Bearer ${token}` },
        params: { startDate, endDate },
      });

      // Adicionando o nome correspondente ao `login` no res.data
      const enrichedData = res.data.map((item) => {
        const matchedUser = usuarios.find((user) => user.login === item.login);
        return {
          ...item,
          nome: matchedUser ? matchedUser.nome : "Nome não encontrado", // Adicionando o nome ou um valor padrão
        };
      });

      // Atualizando o estado `tUsuario` com os dados enriquecidos
      setUsuario(enrichedData);

      // Calculando acessos por login com os dados enriquecidos
      const acessosPorLogin = enrichedData.reduce((acc, curr) => {
        if (!curr.acessos) {
          console.warn(`Propriedade 'acessos' não encontrada para o login: ${curr.login}`);
          return acc; // Pular este registro se não houver 'acessos'
        }
        if (!acc[curr.login]) {
          acc[curr.login] = { login: curr.login, acessos: 0, nome: curr.nome };
        }

        acc[curr.login].acessos += curr.acessos;
        return acc;
      }, {});

      // Convertendo o objeto de volta para um array
      const resultadoFinal = Object.values(acessosPorLogin);

      // Classificando o array pela coluna 'acessos' de forma decrescente
      resultadoFinal.sort((a, b) => b.acessos - a.acessos);

      setResultado(resultadoFinal); // Atualizando o estado resultado
    } catch (error) {
      if (error.response && (error.response.status === 400 || error.response.status === 403 || error.response.status === 401)) {
        window.location.href = `${urlFront}/login`;
      } else {
        console.error("Erro ao obter token:", error);
      }
    }
  };








  useEffect(() => {
    const intervalId = setInterval(() => {
      getToken();
    }, 300000);


    return () => clearInterval(intervalId);
  }, [])





  useEffect(() => {
    const fetchAcessos = async () => {
      try {
        const res = await axios.get(`${urlApi}/portal/acessos`, { headers: { Authorization: `Bearer ${token}` } });

        // Filtra o usuário pelo ID e pelo módulo "gestao"
        const usuarioFiltrado = res.data.find((usuario) => usuario.id_usuario == id && usuario.modulo === "gestao");

        // Verifica se o usuário tem o tipo_acesso permitido
        if (usuarioFiltrado && usuarioFiltrado.tipo_acesso !== "A" && usuarioFiltrado.tipo_acesso !== "G") {
          window.location.href = '/login';
        }
      } catch (error) {
        console.error('Erro ao buscar dados de usuarios:', error);
      }
    };

    fetchAcessos();
  }, [id, token]);







  //Abre o modal 'Ver Usuario'
  const [isModalOpenVerUsuario, setIsModalOpenVerUsuario] = useState(false);
  const modalVerUsuario = () => {
    setIsModalOpenVerUsuario(!isModalOpenVerUsuario);

  };

  const combinarUsuarios = (somandoUsuarios, usuarios) => {
    return somandoUsuarios.map((item) => {
      // Encontra o usuário correspondente em `usuarios` com base no login
      const usuarioCorrespondente = usuarios.find((user) => user.login === item.login);

      // Adiciona o nome encontrado ou uma mensagem padrão caso não encontre
      return {
        ...item,
        nome: usuarioCorrespondente ? usuarioCorrespondente.nome : "Nome não encontrado"
      };
    });
  };

  const resultadoCombinado = combinarUsuarios(somandoUsuarios, usuarios);






  const [semCount, setSemCount] = useState([]);

  const getSemCount = async () => {
    try {
      const res = await axios.get(`${urlApi}/vwacesso/sem-count`, {
        headers: { Authorization: `Bearer ${token}` },
        params: { startDate, endDate },
      });

      // Adicionando o nome correspondente ao `login` no res.data
      const enrichedData = res.data.map((item) => {
        const matchedUser = usuarios.find((user) => user.login === item.login);
        return {
          ...item,
          nome: matchedUser ? matchedUser.nome : "Nome não encontrado", // Adicionando o nome ou um valor padrão
        };
      });

      // Atualizando o estado `tUsuario` com os dados enriquecidos
      setSemCount(enrichedData);


    } catch (error) {
      if (error.response && (error.response.status === 400 || error.response.status === 403 || error.response.status === 401)) {
        window.location.href = `${urlFront}/login`;
      } else {
        console.error("Erro ao obter token:", error);
      }
    }
  };


  const handleConsultarAcessos = () => {
    getUsuario();
    getAcesso();
    getPagina();
    getSemCount();
  };

  const [limparGrafico, setLimparGrafico] = useState('');



  const [logDoUsuario, setLogDoUsuario] = useState('');
  const [isModalOpenVerAcessos, setIsModalOpenVerAcessos] = useState(false);
  const modalVerAcessos = () => {
    setIsModalOpenVerAcessos(!isModalOpenVerAcessos);
  };
  return (
    <>
      {isModalOpenVerAcessos && (
        <ModalContainer>
          <ModalContent>
            <ButtonContainer>
              <ButtonClose onClick={() => {
                modalVerAcessos();

              }}>
                <IoClose color={"red"} size={25} />
              </ButtonClose>
            </ButtonContainer>
            <ModalVerUsuario  logDoUsuario={logDoUsuario} semCount={semCount}/>
          </ModalContent>
        </ModalContainer>
      )}



      <div >
        <DivLayout>
          <Title>
            <div>
              <ButtonSide1 className="toggle-btn desativaBotao" onClick={handleDashboard}>
                <FaArrowLeft />
              </ButtonSide1>
            </div>
            <Title2>
              <div>
                <h2>Acessos</h2>
              </div>


              <img src={logo} alt="Logo" />
            </Title2>

          </Title>


        </DivLayout>
        <DivLayout>
          <Div2>
            <div>
              <Label>Data de Início:</Label>
              <Input
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </div>

            <div>
              <Label>Data de Fim:</Label>
              <Input
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>

            {/* Botão para enviar a requisição */}
            <Button onClick={handleConsultarAcessos}>Consultar Acessos</Button>
            <Button onClick={() => setLimparGrafico('Sim')}>Limpar Gráfico</Button>
          </Div2>
        </DivLayout>

        {tAcesso && tPagina && tUsuario ? (
          <Container>

            <Filtro>

              <GridUsuarios limparGrafico={limparGrafico} setLimparGrafico={setLimparGrafico} setOnEdit={setOnEdit} modalVerUsuario={modalVerUsuario} usuarios={usuarios} tAcesso={tAcesso} tPagina={tPagina} tUsuario={tUsuario} somandoUsuarios={resultadoCombinado} setLogDoUsuario={setLogDoUsuario} modalVerAcessos={modalVerAcessos}/>

            </Filtro>

          </Container>
        ) : (
          null
        )};
        <Div>

          <ToastContainer autoClose={2500} position={toast.POSITION.BOTTOM_LEFT} style={{ width: '400px', fontSize: '22px' }} />
          <GlobalStyle />
        </Div>
      </div>
    </>
  );
}

export default Usuarios;
