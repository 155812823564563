import React, { useState, useEffect, useSyncExternalStore } from "react";
import styled from "styled-components";
import { ImArrowLeft, ImArrowRight } from "react-icons/im";

const Geral = styled.div`
  width: 99%;
  border: 1px solid #ccc;
  padding: 5px;
`;
const Table = styled.table`
  width: 99%;
  background-color: #fff;
  padding: 20px 20px;
  box-shadow: 0px 0px 2px #ccc;
  border-radius: 0.5px;
  margin: 5px auto;

`;

export const TdButton = styled.td`
  font-size: 14px;
  font-weight: bold;
  word-break: break-word;
  padding-top: 15px;
  padding-bottom: 5px;
  cursor: pointer;
`;

export const Thead = styled.thead``;

export const Tbody = styled.tbody`
  padding-bottom: 25px; 
`;

export const Tr = styled.tr`
  text-align: center;
  text-decoration: none;
  border: none;
  width: 100%;
  background-color: #fff;
  cursor: pointer;
  transition: transform 0.3s;

  &:hover {
    color: black;
    background-color: #f5f5f5;
  }
}

`;

export const Th = styled.th`
  color: #4f4f4f;
  text-align: start;
  border-bottom: inset;
  padding-bottom: 5px;


`;

export const Td = styled.td`
  word-break: break-word;
  padding-top: 15px;
  padding-bottom: 5px;
  text-align: ${(props) => (props.alignCenter ? "center" : "start")};
  width: ${(props) => (props.width ? props.width : "auto")};
 

`;

export const Td1 = styled.td`
  padding-top: 15px;
  text-align: center;


`;

export const Td2 = styled.td`
  word-break: break-word;
  padding-top: 15px;
  padding-bottom: 5px;
  text-align: ${(props) => (props.alignCenter ? "center" : "start")};
  width: ${(props) => (props.width ? props.width : "auto")};
 
`;

export const Td3 = styled.td`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  span{
    margin: 0px 5px;
  }

`;

const Input = styled.input`
  width: 345px;
  padding: 0 10px;
  border: 1px solid #bbb;
  border-radius: 1px;
  height: 37px;


`;

const Button = styled.button`
  
  cursor: pointer;
  border: none;

  color: black;

  transition: transform 0.1s;
  &:hover {
    transform: scale(1.3);
  }
`;

const Div = styled.div`
display: flex;
align-items: center;

  
  span{
    padding: 0px  10px;
  }
`;

const DivMedia = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-around;
  }
`;

const Media = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px 30px;
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 10px;

  span {
    font-size: 16px;
  }

  h1 {
    font-size: 32px;
  }
`;

const Grid = ({ exata_CS, treinamentosEXATA }) => {


  const groupedDepartments = exata_CS.reduce((acc, item) => {
    const { cod_departamento, departamento, status } = item;

    if (!acc[cod_departamento]) {
      acc[cod_departamento] = {
        cod_departamento,
        departamento,
        statusCount: { I: 0, P: 0, F: 0 }, // Inicia as contagens de status
      };
    }

    // Contagem de status
    if (status === 'I') acc[cod_departamento].statusCount.I++;
    if (status === 'P') acc[cod_departamento].statusCount.P++;
    if (status === 'F') acc[cod_departamento].statusCount.F++;

    return acc;
  }, {});


  // Converte o objeto agrupado para um array para renderizar
  const currentItems = Object.values(groupedDepartments);


  const [searchTermSetor, setSearchTermSetor] = useState('');
  const [filteredUsers, setFilteredUsers] = useState(currentItems);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const handleSearchSetor = (term) => {
      setIsLoading(true);
      try {
        const filtered = currentItems.filter((user) => {
          const departamento = user.departamento ? user.departamento.toLowerCase() : '';
          return departamento.includes(term.toLowerCase());
        });

        // Verifica se os resultados filtrados são diferentes antes de atualizar o estado
        if (JSON.stringify(filtered) !== JSON.stringify(filteredUsers)) {
          setFilteredUsers(filtered);
        }
      } catch (error) {
        console.error("Erro ao pesquisar:", error);
      }
      setIsLoading(false);
    };

    handleSearchSetor(searchTermSetor);
  }, [searchTermSetor, currentItems, filteredUsers]); // Inclui filteredUsers como dependência







  //Paginação
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 30; // Defina a quantidade de itens por página conforme necessário
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems2 = filteredUsers.slice(indexOfFirstItem, indexOfLastItem);


  const totalPages = Math.ceil(filteredUsers.length / itemsPerPage);
  // Função para ir para a próxima página
  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };
  // Função para ir para a página anterior
  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };





  const [totalTreinamento, setTotalTreinamento] = useState('');
  const [totalPresente, setTotalPresente] = useState('');

  useEffect(() => {
    if (treinamentosEXATA) {
      const totalTreinamento = treinamentosEXATA.length;
      setTotalTreinamento(totalTreinamento.toLocaleString('pt-BR')); 
    }

  }, [treinamentosEXATA]);



  const contarStatus = (exata_CS) => {
    const contagem = exata_CS.reduce((acc, user) => {
      const status = user.status;
      if (!acc[status]) {
        acc[status] = 0;
      }
      acc[status] += 1;
      return acc;
    }, {});
  
    // Formata os valores de contagem com .toLocaleString('pt-BR')
    const contagemFormatada = Object.fromEntries(
      Object.entries(contagem).map(([key, value]) => [key, value.toLocaleString('pt-BR')])
    );
  
    return contagemFormatada;
  };


  const contagemStatus = contarStatus(exata_CS);

  /*
  const contarStatus = (exata_CS) => {
    const contagem = exata_CS.reduce((acc, user) => {
      const status = user.status; // Obtém o valor do status
      if (!status || !['P', 'I', 'F'].includes(status)) {
        if (!acc["Invalid"]) {
          acc["Invalid"] = []; // Inicializa uma lista para valores inválidos
        }
        acc["Invalid"].push(user); // Adiciona o usuário com status inválido
      } else {
        if (!acc[status]) {
          acc[status] = 0; // Inicializa o contador para o status
        }
        acc[status] += 1; // Incrementa o contador para o status
      }
      return acc;
    }, {});
  
    return contagem; // Retorna um objeto com a contagem de cada status e os valores inválidos
  };
  
  // Exemplo de uso:
  const contagemStatus = contarStatus(exata_CS);
  console.log("Contagem de status:", contagemStatus);
  console.log("Registros com status inválido:", contagemStatus.Invalid);*/


  
  return (

    <Geral>
      <DivMedia>
        <Media>
          <span>Total de Treinamentos:</span>
          <h1>{totalTreinamento}</h1>
        </Media>
        <Media>
          <span>Total de Presentes:</span>
          <h1>{contagemStatus.P}</h1>
        </Media>
        <Media>
          <span>Total de Imprevistos:</span>
          <h1>{contagemStatus.I}</h1>
        </Media>
        <Media>
          <span>Total de Faltantes:</span>
          <h1>{contagemStatus.F}</h1>
        </Media>

      </DivMedia>
      <Td3 colSpan="7">
        <Input
          type="text"
          placeholder="Pesquisar setor"
          value={searchTermSetor}
          onChange={(e) => setSearchTermSetor(e.target.value)}
        />

        <Div>
          <div>
            <Button onClick={handlePrevPage} disabled={currentPage === 1}><ImArrowLeft size={20} /></Button>
            <span>Página <strong>{currentPage}</strong> de <strong>{totalPages}</strong></span>
            <Button onClick={handleNextPage} disabled={currentPage === totalPages}> <ImArrowRight size={20} /></Button>
          </div>
        </Div>
      </Td3>
      <Table >

        <Thead>
          <Tr>
            <Th>Cód.:</Th>
            <Th>Departamento:</Th>
            <Th>Presente:</Th>
            <Th>Faltante:</Th>
            <Th>Imprevisto:</Th>
          </Tr>
        </Thead>
        <Tbody>
          {currentItems2.map((dept, i) => (
            <Tr key={i}>
              <Td>{dept.cod_departamento}</Td> {/* Exibe o código do departamento */}
              <Td>{dept.departamento}</Td>      {/* Exibe o nome do departamento */}
              <Td>{dept.statusCount.P}</Td> {/* Exibe a quantidade de 'Presente' */}
              <Td>{dept.statusCount.F}</Td> {/* Exibe a quantidade de 'Faltante' */}
              <Td>{dept.statusCount.I}</Td>   {/* Exibe a quantidade de 'Inativo' */}
            </Tr>
          ))}

        </Tbody>

      </Table>

    </Geral>
  );
};


export default Grid;
