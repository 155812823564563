import React, { useState, useEffect } from "react";
import axios from 'axios';

import TrocarSenha from "./components/TrocarSenha.js";
import Frame from './components/Frame.js';

import { useUsuariosHook } from '../../../hook/getUsuarios.js';
import { useModuloHook } from '../../../hook/getModulo.js';
import { usePaginaHook } from '../../../hook/getPagina.js';
import { useAcessoHook } from '../../../hook/getAcesso.js';

import GlobalStyle from "../../../styles/global.js";
import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components";
import { FaTractor, FaBars, FaHandshake, FaBox } from "react-icons/fa";
import { ImExit } from "react-icons/im";
import { toast, ToastContainer } from "react-toastify";
import { SlSizeFullscreen } from "react-icons/sl";
import { FaMagnifyingGlass, FaPeopleGroup, FaGear, FaAddressCard} from "react-icons/fa6";
import { LuBookMarked } from "react-icons/lu";
import { RiBarChart2Fill, RiArrowGoBackFill } from "react-icons/ri";
import { MdOutlineClose } from "react-icons/md";
import { GrUserAdmin } from "react-icons/gr";



const Geral = styled.div`
  div {
    cursor: pointer;
    transition: transform 0.1s;
    background-color: ${(props) => (props.currentUrl ? 'gray' : '#076633')};
    border-radius: ${(props) => (props.currentUrl ? '3px' : '3px')};
  }
`;

const Div = styled.div`
  width: 100%;
  margin-left: 65px;
`;

const DivLayout = styled.aside`
  width: 60px;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: ${(props) => (props.isOpen ? "250px" : "60px")};
  //background-color: #11101d;
  background-color: #076633;
  color: white;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: width 0.3s;
`;

const Divbutton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const Divbuttons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  margin: 0px 0px 20px 5px;
`;

const ListItem = styled.li`
  width: 100%;
  padding: 10px 0;
  cursor: pointer;
  font-size: 22px;
  list-style-type: none;

  h4 {
    font-size: 18px;
    padding-left: 15px;
    cursor: pointer;
    transition: transform 0.1s;
    &:hover {
      color: #11101d;
    }
  }
`;

const NestedList = styled.ul`
  width: 90%;
  background-color: gray;
  list-style-type: none;
  font-size: 18px;
  padding: 5px 5px 5px 20px;
  margin-top: 10px;  // Adicionar um espaçamento entre o título e a lista

  li {
    padding: 5px 0;
    transition: transform 0.1s;
    &:hover {
      transform: scale(1.01);
      color: #11101d;
    }
  }
`;


const ListItemEst = styled.li`
  width: 100%;

  padding: 15px 10px;
  font-size: 22px;
  list-style-type: none;
  h4 {
    font-size: 20px;
    padding-left: 10px;
  }
`;

const NestedListEst = styled.ul`
  background-color: gray;
  list-style-type: none;
  font-size: 20px;
  padding: 5px 5px 5px 20px;

  li {
    padding: 5px;
    transition: transform 0.1s;
    &:hover {
      transform: scale(1.01);
      color: #11101d;
    }
  }
  
`;

const ButtonSide2 = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  background-color: #076633;
  font-size: 18px;
  color: #fff;
  padding: 10px;
  gap: 10px;
  transition: transform 0.1s;

  &:hover {
    color: #11101d;
  }

`;

const ButtonSide23 = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  background-color: gray;
  font-size: 18px;
  color: #fff;
  padding: 10px;
  gap: 10px;
  transition: transform 0.1s;

  &:hover {
    color: #11101d;
  }

`;

const ToggleButton = styled.button`
  font-size: 20px;
  width: auto;
  height: 25px;
  padding:  20px 20px 5px 20px;
  margin-bottom: 20px;
  background-color: #076633;
  color: white;
  border: none;
  cursor: pointer;
  z-index: 1000;
  display: flex;
  align-items: center;
  gap: 50px;

  .button-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  img {
    margin-left: 10px;
  }
`;






function App() {

  //----------------------------//
  //Ip's da api e do front
  //const urlFront = "http://192.168.100.103:3050/gestao";
  //const urlApi = "http://192.168.100.103:8850/api";
  //const urlLogin = "http://192.168.100.103:3050/";
  const urlFront = "/gestao/";
  const urlApi = "/api";
  const urlLogin = "/";

  //----------------------------//

  //Token da api
  const token = localStorage.getItem('token');
  const id = localStorage.getItem('id');
  const usuario = localStorage.getItem('usuario');



  //----------------------------//

  //Retorna os hook
  const { usuarios } = useUsuariosHook();
  const { modulo } = useModuloHook();
  const { pagina } = usePaginaHook();
  const { acesso } = useAcessoHook();

  //----------------------------//

  //Verifica quais os PBIs o usuário tem acesso
  const filteredAcesso = acesso.filter((item) => item.id_usuario == id);

  const acessoModuloIds = filteredAcesso.map((item) => item.id_modulo);
  const acessoPaginaIds = filteredAcesso.map((item) => item.id_pagina);
  const filteredModulos = modulo.filter((mod) => acessoModuloIds.includes(mod.id_modulo));

  const filteredPaginas = pagina.filter(
    (pag) => acessoModuloIds.includes(pag.id_modulo) && acessoPaginaIds.includes(pag.id_pagina)
  );

  //----------------------------//

  // Verifica se o usuário é admin
  const [tipoUsuario, setTipoUsuario] = useState('');

  useEffect(() => {
    const fetchAcessos = async () => {
      try {
        const res = await axios.get(`${urlApi}/portal/acessos`, { headers: { Authorization: `Bearer ${token}` } });
        // Filtra o usuário pelo ID e pelo módulo "gestao"
        const usuarioFiltrado = res.data.find((usuario) => usuario.id_usuario == id && usuario.modulo === "gestao");
  
        if (usuarioFiltrado) {
          setTipoUsuario(usuarioFiltrado.tipo_acesso);
        } else {
          setTipoUsuario(''); // Define um valor padrão caso não encontre o usuário
        }
      } catch (error) {
        console.error('Erro ao buscar dados de usuarios:', error);
      }
    };
  
    fetchAcessos(); // Chama a função de busca
  }, [id, token]);
  

  
  //----------------------------//

  //Passa os links para o Frame.js redenrizar
  const [currentUrl, setCurrentUrl] = useState(null);
  const handleUrlClick = (pag) => {
    console.log(pag.url)
    setCurrentUrl(pag.url);
    handleLog(pag)

    if (pag.url === null) {
      handleUrlClick();
    }
    setIsOpen(false);
  };


  //----------------------------//


  const handleLog = async (pag) => {
    try {

      const response = await axios.post(`${urlApi}/logpbi`, {
        login: usuario,
        id_modulo: pag.id_modulo,
        id_pagina: pag.id_pagina,
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });

      console.log("Log inserido com sucesso:", response.data);
    } catch (error) {
      console.error("Erro ao inserir log:", error);
    }
  };

  //----------------------------//


  //Links
  const handleUsuarios = () => {
    const url = `cadastro-usuarios/`;
    window.location.href = url;
  };
  const handleEquipamentosServico = () => {
    const url = `cadastro-url/`;
    window.location.href = url;
  };
  const handleExit = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('id');
    localStorage.removeItem('usuario');

    const url = `${urlLogin}login`;
    window.location.href = url;
  };


  const handleAcessos = () => {
    const url = `acessos/`;
    window.location.href = url;
  };
  //----------------------------//

  //Icones
  const getIconById = (id_modulo) => {
    switch (id_modulo) {
      case 1:
        return <FaTractor />;
      case 3:
        return <FaGear />;
      case 4:
        return <RiBarChart2Fill />;
      case 5:
        return <FaPeopleGroup />;
      case 6:
        return <FaBox />;
      case 7:
        return <FaMagnifyingGlass />;
      case 8:
        return <LuBookMarked />;
      case 9:
        return <FaHandshake />;


      default:
        return id_modulo; // Ícone padrão
    }
  };






  //----------------------------//

  //Verifica o token do usuário
  const getToken = async () => {
    try {
      const res = await axios.get(`${urlApi}/login/token`, { headers: { Authorization: `Bearer ${token}` } });

    } catch (error) {
      if (error.response && (error.response.status === 400 || error.response.status === 403 || error.response.status === 401)) {
        window.location.href = `${urlFront}/login`;
      } else {
        console.error("Erro ao obter token:", error);
      }
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      getToken();
    }, 60000); 
  
  
    return () => clearInterval(intervalId);
  }, [])


  //Efeitos do sidebar
  const [isOpen, setIsOpen] = useState(false);
  const toggleSidebar = () => {

    setIsOpen(!isOpen);
  };

  const toggleModule = (id) => {
    setOpenModules((prevOpenModules) => {
      const newOpenModules = {};
      Object.keys(prevOpenModules).forEach(moduleId => {
        newOpenModules[moduleId] = false;
      });
      return {
        ...newOpenModules,
        [id]: !prevOpenModules[id],
      };
    });
  };

  const handleItemClick = (id, event) => {
    toggleModule(id);
    handleMouseEnter(id, event);
  };


  const [openModules, setOpenModules] = useState({});
  const [hoveredModule, setHoveredModule] = useState(null);
  const [isMouseOver, setIsMouseOver] = useState(null);
  const [isMouseOverTwo, setIsMouseOverTwo] = useState(null);



  const [elementPosition, setElementPosition] = useState({ top: 0, left: 0 });

  const handleMouseEnter = (id, event) => {
    setIsMouseOver(true);
    const rect = event.target.getBoundingClientRect();
    setElementPosition({
      top: rect.top,
      left: rect.left
    });
    setHoveredModule(id);
  };



  const handleMouseLeave = () => {
    setIsMouseOver(false);
  };

  const teste = () => {

    if (isMouseOver === false) {
      setTimeout(function () {
        setHoveredModule(null);
      }, 1000);
    }


  };

  useEffect(() => {
    teste();
  }, [isMouseOver, isMouseOverTwo]);

  const handleNestedMouseEnter = (id) => {

    setIsMouseOverTwo(true);
    setIsMouseOverTwo(true);
    setHoveredModule(id);

  };
  const handleNestedMouseLeave = (id) => {
    setIsMouseOverTwo(false)
    setHoveredModule(null);
  };

  //----------------------------//


  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen().catch((err) => {
        console.log(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
      });
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  };


  const toggleInicio = () => {
    const url = `${urlLogin}`;
    window.location.href = url;
  };



  const [isAdminOpen, setIsAdminOpen] = useState(false);
  const handleToggleAdmin = () => {
    setIsAdminOpen(prevState => !prevState); // Alterna a visibilidade
  };

  return (
    <>
      <Geral>


        <Div>
          <Frame currentUrl={currentUrl} />
        </Div>
        {filteredModulos.map((mod, index) => (
          !isOpen && (
            <div
              key={mod.id_modulo}
              style={{ color: '#fff', position: 'fixed', left: '70px', left: `${elementPosition.left + 60}px`, top: `${elementPosition.top - 7}px`, zIndex: '999' }}
              onMouseEnter={() => handleMouseEnter(mod.id_modulo)}
              onMouseLeave={() => handleNestedMouseLeave()}
            >
              {hoveredModule === mod.id_modulo && (
                <ListItemEst >
                  <h4 onClick={() => handleItemClick(mod.id_modulo)}>{getIconById(mod.id_modulo)} {mod.desc_modulo}</h4>

                  <NestedListEst
                    onMouseEnter={() => handleNestedMouseEnter(mod.id_modulo)}
                    onMouseLeave={() => handleNestedMouseLeave()}
                    style={{ backgroundColor: "transparent" }}
                  >
                    {filteredPaginas
                      .filter((pag) => pag.id_modulo === mod.id_modulo)
                      .map((pag) => (
                        <li key={pag.id_pagina} onClick={() => handleUrlClick(pag)}>
                          {pag.desc_pagina}
                        </li>
                      ))}
                  </NestedListEst>

                </ListItemEst>
              )}
            </div>
          )
        ))}


        <DivLayout isOpen={isOpen} >
          <Divbutton>
            <ToggleButton onClick={toggleSidebar}>{isOpen ? <>
              <ButtonSide2 onClick={toggleInicio} title="Tela inicial">
                {isOpen ? <><RiArrowGoBackFill /> Inicio</> : <RiArrowGoBackFill />}
              </ButtonSide2>

              <MdOutlineClose color={"red"} size={28} /> </> : <FaBars />}</ToggleButton>

          </Divbutton>

          <ul>
            {filteredModulos.map((mod, index) => (
              <ListItem key={mod.id_modulo} onClick={() => toggleModule(mod.id_modulo)} onMouseEnter={(event) => handleMouseEnter(mod.id_modulo, event)} onMouseLeave={() => handleMouseLeave()}>

                <h4  >{getIconById(mod.id_modulo)} {isOpen && mod.desc_modulo}</h4>
                {isOpen && openModules[mod.id_modulo] && (
                  <NestedList>
                    {filteredPaginas
                      .filter((pag) => pag.id_modulo === mod.id_modulo)
                      .map((pag) => (
                        <li key={pag.id_pagina} onClick={() => handleUrlClick(pag)}>
                          {pag.desc_pagina}
                        </li>
                      ))}
                  </NestedList>
                )}
              </ListItem>
            ))}
          </ul>

          <Divbuttons>

            <ButtonSide2 onClick={toggleFullscreen} title="Tela cheia">

              {isOpen ? <><SlSizeFullscreen /> Tela cheia</> : <SlSizeFullscreen />}

            </ButtonSide2>


            {isOpen ? (
              <>
                <>
                  {(tipoUsuario === 'G' || tipoUsuario === 'A') && (
                    <ButtonSide2 onClick={handleAcessos} title="Admin">
                      <FaAddressCard /> Acessos
                    </ButtonSide2>

                  )}
                </>



                {tipoUsuario === 'A' && (
                  <ListItem>
                    {/* Botão para exibir os botões de admin */}
                    <ButtonSide2 onClick={handleToggleAdmin} title="Admin">
                      <GrUserAdmin /> Admin
                    </ButtonSide2>

                    {/* Exibir os botões quando isAdminOpen for true */}
                    {isAdminOpen && (
                      <NestedList>
                        <ButtonSide23 onClick={handleEquipamentosServico} title="Cadastro Módulos">
                          Links
                        </ButtonSide23>
                        <ButtonSide23 onClick={handleUsuarios} title="Cadastro Usuários">
                          Usuários
                        </ButtonSide23>
                        {/**/}
                      </NestedList>
                    )}
                  </ListItem>

                )}

                {/*<ButtonSide2 onClick={trocarSenha} title="Trocar a senha do portal">
                  {isOpen ? <><FaUnlock /> Trocar a senha</> : <FaUnlock />}
                </ButtonSide2>*/}
                <ButtonSide2 onClick={handleExit} title="Sair do portal">
                  {isOpen ? <><ImExit /> Sair</> : <ImExit />}

                </ButtonSide2>
              </>


            ) : null}


          </Divbuttons>

        </DivLayout>

        <GlobalStyle />
      </Geral>
      <ToastContainer autoClose={2500} position={toast.POSITION.BOTTOM_LEFT} style={{ width: '400px', fontSize: '22px' }} />
    </>
  );
}

export default App;
