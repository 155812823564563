import { useState, useEffect } from 'react';
import axios from 'axios';

//const url = "http://192.168.100.103:8850/api";
const url = "/api";
const token = localStorage.getItem('token');


export const useVisualizadorHook = () => {
    const [visualizador, setVisualizador] = useState([]);


    const fetchVisualizador = async () => {
        try {
            const res = await axios.get(`${url}/visualizador-gerenciamento`, { headers: { Authorization: `Bearer ${token}` } });
            setVisualizador(res.data);
        } catch (error) {
            console.error('Erro ao buscar dados do visualizador:', error);
        }
    };


    useEffect(() => {
        fetchVisualizador();
    }, [token]);

    return { visualizador, fetchVisualizador };
};
