import { useState, useEffect } from 'react';
import axios from 'axios';

//const url = "http://192.168.100.103:8850/api";
const url = "/api";
const token = localStorage.getItem('token');


export const useCheckboxHook = () => {
    const [checkbox, setCheckbox] = useState([]);


    const fetchCheckbox = async () => {
        try {
            const res = await axios.get(`${url}/gerenciamento-checkbox`, { headers: { Authorization: `Bearer ${token}` } });
            setCheckbox(res.data);
        } catch (error) {
            console.error('Erro ao buscar dados do checkbox:', error);
        }
    };


    useEffect(() => {
        fetchCheckbox();
    }, [token]);

    return { checkbox, fetchCheckbox };
};
