import React from "react";
import styled from "styled-components";


const Table = styled.table`
  width: 800px;
  background-color: #fff;
  padding: 20px 20px;
  box-shadow: 0px 0px 2px #ccc;
  border-radius: 0.5px;
  margin: 1%;

`;



export const TdButton = styled.td`
  font-size: 14px;
  font-weight: bold;
  word-break: break-word;
  padding-top: 15px;
  padding-bottom: 5px;
  cursor: pointer;
`;

export const Thead = styled.thead``;

export const Tbody = styled.tbody`
  padding-bottom: 25px; 
`;

export const Tr = styled.tr`
  text-align: center;
  &:hover {
    color: black;
    background-color: #f5f5f5;
  }

`;

export const Th = styled.th`
  color: #4f4f4f;
  text-align: start;
  border-bottom: inset;
  padding-bottom: 5px;


`;

export const Td = styled.td`
  word-break: break-word;
  padding-top: 15px;
  padding-bottom: 5px;
  text-align: ${(props) => (props.alignCenter ? "center" : "start")};
  width: ${(props) => (props.width ? props.width : "auto")};
 

`;

export const Td1 = styled.td`
  padding-top: 15px;
  text-align: center;


`;

export const Td2 = styled.td`
  word-break: break-word;
  padding-top: 15px;
  padding-bottom: 5px;
  text-align: ${(props) => (props.alignCenter ? "center" : "start")};
  width: ${(props) => (props.width ? props.width : "auto")};
 
`;

export const Td3 = styled.td`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  span{
    margin: 0px 5px;
  }

`;



const Grid = ({   logDoUsuario, semCount }) => {

  const filteredData = semCount.filter(item => 
    item.login === logDoUsuario.login && 
    item.id_modulo === logDoUsuario.id_modulo && 
    item.id_pagina === logDoUsuario.id_pagina
  );



  return (
    <>
      <Table>
        <Thead>
          <Tr>
            <Th>Login</Th>
            <Th>Nome</Th>
            <Th>Modulo</Th>
            <Th>Pagina</Th>
            <Th>Data</Th>
          </Tr>
        </Thead>
        <Tbody>
          {/* Mapear os dados filtrados para exibir na tabela */}
          {filteredData.map((item, index) => (
            <Tr key={index}>
              <Td width="auto">{item.login}</Td>
              <Td width="auto">{item.nome}</Td>
              <Td width="auto">{item.desc_modulo}</Td>
              <Td width="auto">{item.desc_pagina}</Td>
              <Td width="auto">{item.data}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </>
  );
};


export default Grid;
