import React from 'react';
import './Sidebar.css';
import logo from './Logomarca Cooperval-05.png';
import styled from "styled-components";


const BackgroundImage = styled.img`
  position: absolute;
  top: 50%; /* Centraliza verticalmente */
  left: 50%; /* Centraliza horizontalmente */
  transform: translate(-50%, -50%); /* Ajusta a imagem para o centro exato */
  width: 100vw;
  height: 100vh;
  z-index: -1; 
`;

const GridBi = ({ currentUrl }) => {
  return (
    <>
      {currentUrl ? (
        <iframe src={currentUrl} title="iframe" className='iframe'></iframe>
      ) : (
        
        <BackgroundImage src={logo} style={{ width: '500px', height: '100px', padding: '0px 20px 20px 0px' }} alt="Logo" />  
        
      )}
    </>
  );
};

export default GridBi;
