import React from 'react';
import ReactApexChart from 'react-apexcharts';

const BarChart = ({ somandoUsuarios, onUserSelect }) => {
  const seriesData = Array.isArray(somandoUsuarios) ? somandoUsuarios.map(item => item.acessos) : [];
  const categoriesData = Array.isArray(somandoUsuarios) ? somandoUsuarios.map(item => item.login) : [];
  const namesData = Array.isArray(somandoUsuarios) ? somandoUsuarios.map(item => item.nome) : [];

  const options = {
    chart: {
      type: 'bar',
      height: categoriesData.length * 50,
      width: '100%',
      events: {
        dataPointSelection: (event, chartContext, config) => {
          const selectedId = categoriesData[config.dataPointIndex];
          onUserSelect(selectedId);
        }
      },
      toolbar: {
        show: false
      }
    },
    colors: ['#04a44c'],
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true,
        barHeight: '80%',
        dataLabels: {
          position: 'end'
        }
      }
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, { dataPointIndex, w }) {
        const acessos = seriesData[dataPointIndex];
        return `${acessos}`;
      },
      style: {
        colors: ['#333'],
        fontSize: '14px',
        fontWeight: 'bold'
      },
      offsetX: 15,
      textAnchor: 'start',
    },
    xaxis: {
      categories: categoriesData,
    },
    tooltip: {
      enabled: true,
      y: {
        formatter: function (val, { dataPointIndex }) {
          const userName = namesData[dataPointIndex];
          const acessos = seriesData[dataPointIndex];
          return `${userName}: ${acessos} acessos`;
        },
        title: {
          formatter: () => 'Usuário'
        }
      },
      custom: ({ series, seriesIndex, dataPointIndex, w }) => {
        const userName = namesData[dataPointIndex];
        const acessos = series[seriesIndex][dataPointIndex];
        return (
          `<div style="background: #333; color: #fff; padding: 10px; border-radius: 5px; text-align: center;">
            <strong>${userName}</strong><br />${acessos} acessos
          </div>`
        );
      },
    }
  };
  

  const series = [{
    data: seriesData,
  }];

  return (
    <div style={{ overflowY: 'auto', maxHeight: '300px' }}>
      <div id="chart">
        <ReactApexChart options={options} series={series} type="bar" height={categoriesData.length * 50} />
      </div>
    </div>
  );
};

export default BarChart;
