import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { ImArrowLeft, ImArrowRight } from "react-icons/im";


const Geral = styled.div`
  width: 99%;
  border: 1px solid #ccc;
  padding: 5px;
`;
const Table = styled.table`
  width: 99%;
  background-color: #fff;
  padding: 20px 20px;
  box-shadow: 0px 0px 2px #ccc;
  border-radius: 0.5px;
  margin: 5px auto;

`;

export const TdButton = styled.td`
  font-size: 14px;
  font-weight: bold;
  word-break: break-word;
  padding-top: 15px;
  padding-bottom: 5px;
  cursor: pointer;
`;

export const Thead = styled.thead``;

export const Tbody = styled.tbody`
  padding-bottom: 25px; 
`;

export const Tr = styled.tr`
  text-align: center;
  text-decoration: none;
  border: none;
  width: 100%;
  background-color: #fff;
  cursor: pointer;
  transition: transform 0.3s;

  &:hover {
    color: black;
    background-color: #f5f5f5;
  }
}

`;

export const Th = styled.th`
  color: #4f4f4f;
  text-align: start;
  border-bottom: inset;
  padding-bottom: 5px;
  padding: 5px;

`;

export const Td = styled.td`
  word-break: break-word;
  padding-top: 15px;
  padding-bottom: 5px;
  text-align: ${(props) => (props.alignCenter ? "center" : "start")};
  width: ${(props) => (props.width ? props.width : "auto")};
 

`;
export const Td2 = styled.td`
  word-break: break-word;
  padding-top: 15px;
  padding-bottom: 5px;
  align-items: center;

  &:hover {
    color: black;
    background-color: #f5f5f5;
  }

`;



export const Td3 = styled.td`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  span{
    margin: 0px 5px;
  }

`;

const Input = styled.input`
  width: 345px;
  padding: 0 10px;
  border: 1px solid #bbb;
  border-radius: 1px;
  height: 37px;


`;

const Button = styled.button`
  cursor: pointer;
  border: none;
  color: black;
  background-color: transparent;
  transition: transform 0.1s;

  &:hover {
    transform: scale(1.3);
  }
`;

const Div = styled.div`
  display: flex;
  justify-content: center; /* Centraliza os elementos no eixo horizontal */
  align-items: center; /* Centraliza os elementos no eixo vertical */
  padding: 20px;
`;

export const Tr1 = styled.div`

  width: 100%;
  background-color: #f9f9f9;
  margin-bottom: 15px; /* Espaço entre as linhas */

  div {
    display: flex;
    flex-direction: row; /* Organiza os textos um abaixo do outro */
    gap: 10px; /* Espaço entre os itens */
    padding: 10px;
    background-color: #fff;
    border-radius: 8px; /* Bordas arredondadas */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Adiciona uma leve sombra */
  }
  
  p {
      text-align: start;
  }
`;

const Div2 = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  
  margin: 0 auto;
  gap: 20px; /* Espaço entre os blocos */
`;

const Div3 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center; /* Centraliza o conteúdo dentro da coluna */
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  background-color: #f4f4f4;
  gap: 20px; /* Espaço entre as seções de usuário e cursos */
`;


const Grid = ({ modalCargosRepetidos, cargosRepitidosCS, cursosCargoCS }) => {


  const countMap = cargosRepitidosCS.reduce((map, item) => {
    const key = item[3]; // Valor do índice [1]
    map[key] = (map[key] || 0) + 1;
    return map;
  }, {});




  // Passo 2: Filtrar apenas os itens de cargosRepitidosCS que têm valores duplicados
  const semCargosDuplicados = cargosRepitidosCS.filter(item => countMap[item[3]] > 1);
  //console.log('duplicados', semCargosDuplicados);

  const cursosPorCargo = {};

  // Passo 2: Agrupar os cursos pelo ID do cargo
  cursosCargoCS.forEach(curso => {
    const key = `${curso[0]}-${curso[1]}-${curso[2]}`; // Usar o ID do cargo como chave
    if (!cursosPorCargo[key]) {
      cursosPorCargo[key] = { // Se não existir, inicializa um novo array
        cargoId: key,
        cursos: []
      };
    }
    cursosPorCargo[key].cursos.push(curso); // Adiciona o curso ao cargo
  });

  // Passo 3: Criar o objeto final com a estrutura desejada
  const resultadoFinal = semCargosDuplicados.map(cargo => {
    const key = `${cargo[0]}-${cargo[1]}-${cargo[2]}`; // Usar o ID do cargo como chave
    return {
      ...cargo, // Inclui os detalhes do cargo
      cursos: cursosPorCargo[key] ? cursosPorCargo[key].cursos : [] // Adiciona os cursos, se houver
    };
  });

  const resultadoFiltrado = resultadoFinal.filter(item =>
    cursosCargoCS.some(curso => curso[3] === item[3]) // Verifica se item[3] de resultadoFinal existe em cursosCargoCS
  );








  const cargosUnicos = [...new Set(resultadoFiltrado.map(item => item[3]))];






  const [cargoFiltrado, setCargoFiltrado] = useState([]);
  const [listaVisivel, setListaVisivel] = useState({});
  // Função para manipular o clique no cargo
  const handleCargo = (dept) => {
    modalCargosRepetidos(dept);
    // Filtra o resultadoFiltrado com base no cargo clicado
    /*const filtrado = resultadoFiltrado.filter(item => item[3] === dept);

    // Atualiza o estado com o resultado filtrado
    setCargoFiltrado(filtrado);

    // Alterna a visibilidade da subtabela do cargo clicado
    setListaVisivel((prev) => ({
      ...prev,
      [dept]: !prev[dept], // Inverte o estado de visibilidade para o cargo clicado
    }));
    */
  };











  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const handleSearch = async (term) => {
    setIsLoading(true);

    try {
      const filtered = cargosUnicos.filter((curso) => {
        // Verifica se o valor do curso contém o termo de pesquisa
        return curso.toLowerCase().includes(term.toLowerCase());
      });

      setFilteredUsers2(filtered);
    } catch (err) {
      console.log("Erro ao filtrar:", err);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    handleSearch(searchTerm);
  }, [searchTerm]);




  const [filteredUsers2, setFilteredUsers2] = useState(cargosUnicos); // Inicializando como array vazio

  // Paginação
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  // Verifica se `filteredUsers2` é um array e faz o slice
  const currentItems = Array.isArray(filteredUsers2) ? filteredUsers2.slice(indexOfFirstItem, indexOfLastItem) : [];
  // Verifica se `filteredUsers2` existe e tem length, caso contrário assume 0
  const totalPages = filteredUsers2 && Array.isArray(filteredUsers2) ? Math.ceil(filteredUsers2.length / itemsPerPage) : 1;
  // Função para ir para a próxima página
  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };
  // Função para ir para a página anterior
  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };




  return (

    <Geral>

      <Td3 colSpan="7">
        <Input
          type="text"
          placeholder="Pesquisar função"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />

        <Div>
          <div>
            <Button onClick={handlePrevPage} disabled={currentPage === 1}><ImArrowLeft size={20} /></Button>
            <span>Página <strong>{currentPage}</strong> de <strong>{totalPages}</strong></span>
            <Button onClick={handleNextPage} disabled={currentPage === totalPages}> <ImArrowRight size={20} /></Button>
          </div>
        </Div>
      </Td3>
      <Table>
        <Thead>
          <Tr>
            <Th>Função:</Th>
          </Tr>
        </Thead>
        <Tbody>
          {currentItems.map((dept, i) => (
            <React.Fragment key={i}>
              {/* Linha principal do cargo */}
              <Tr key={i} onClick={() => handleCargo(dept)}>
                <Td>{dept}</Td>
              </Tr>

              {/* Verifica se a subtabela desse cargo está visível */}
              {listaVisivel[dept] && (

                <Div>
                  <Div2>
                    {cargoFiltrado.map((usuario, index) => (
                      <React.Fragment key={index}>
                        <Div3>
                          {/* Exibe as informações principais do usuário */}
                          <Tr1>
                            <div>
                              <p>cód: {usuario[2]}</p> {/* Terceiro valor */}
                              <p>cargo: {usuario[3]}</p> {/* Nome do cargo */}
                            </div>
                          </Tr1>

                          {/* Mapeia e exibe os cursos associados a este cargo */}
                          {usuario.cursos.map((curso, cursoIndex) => (
                            <div key={cursoIndex}>
                              <p>Descrição: {curso[5]}</p> {/* Nome do curso */}
                            </div>
                          ))}
                        </Div3>
                      </React.Fragment>
                    ))}
                  </Div2>
                </Div>

              )}
            </React.Fragment>
          ))}
        </Tbody>
      </Table>
    </Geral >
  );
};


export default Grid;
