import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { FaMagnifyingGlass } from "react-icons/fa6";

const Geral = styled.div`
  width: 99%;
  border: 1px solid #ccc;
  padding: 5px;
`;
const Table = styled.table`
  width: 99%;
  background-color: #fff;
  padding: 20px 20px;
  box-shadow: 0px 0px 2px #ccc;
  border-radius: 0.5px;
  margin: 5px auto;

`;


export const TdButton = styled.td`
  font-size: 14px;
  font-weight: bold;
  word-break: break-word;
  padding-top: 15px;
  padding-bottom: 5px;
  cursor: pointer;
`;

export const Thead = styled.thead``;

export const Tbody = styled.tbody`
  padding-bottom: 25px; 
`;

export const Tr = styled.tr`
  text-align: center;
  text-decoration: none;
  border: none;
  width: 100%;
  background-color: #fff;
  cursor: pointer;
  transition: transform 0.3s;

  &:hover {

    transform: scale(1.00);
    color: gray;

  }
}

`;

export const Tr2 = styled.tr`

`;
export const Th = styled.th`
  color: #4f4f4f;
  text-align: start;
  border-bottom: inset;
  padding-bottom: 5px;


`;

export const Td = styled.td`
  word-break: break-word;
  padding-top: 15px;
  padding-bottom: 5px;
  text-align: ${(props) => (props.alignCenter ? "center" : "start")};
  width: ${(props) => (props.width ? props.width : "auto")};
 

`;

export const Td1 = styled.td`
  padding-top: 15px;
  text-align: center;


`;

export const Td2 = styled.td`
  word-break: break-word;
  padding-top: 15px;
  padding-bottom: 5px;
  text-align: ${(props) => (props.alignCenter ? "center" : "start")};
  width: ${(props) => (props.width ? props.width : "auto")};
 
`;

export const Td3 = styled.td`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: 9px;
  span{
    margin: 0px 5px;
  }

`;

const Input = styled.input`
  width: 345px;
  padding: 0 10px;
  border: 1px solid #bbb;
  border-radius: 1px;
  height: 37px;


`;

const Button = styled.button`
  cursor: pointer;
  border: none;
  background-color:  #f5f5f5;
  font-weight: bold;
  font-size: 17px;
  margin: 8px;
  transition: transform 0.1s;
  &:hover {
    transform: scale(1.1);
  }
  
`;


const Button2 = styled.button`
  padding: 5px 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #45a049;
  }
`;


const Grid = ({ programacaoFiltreded, setRecebeValorImput, servicoFiltreded, setOnEdit, toggleModalMateriais }) => {


  const [searchTerm, setSearchTerm] = useState('');


  const handleSearch = async (term) => {
    setRecebeValorImput(searchTerm); // Salva o valor na prop
  };


  const handleEdit = (item) => {
    setOnEdit(item);
    toggleModalMateriais();

  };


  const [expandedRows, setExpandedRows] = useState({});

  const toggleRow = (index) => {
    setExpandedRows((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  return (

    <Geral>
      <Td3 colSpan="7">
        <Input
          type="text"
          placeholder="Pesquisar... Ex: 2024/648"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <Button onClick={handleSearch}>
          <FaMagnifyingGlass color={"blue"} /> Pesquisar
        </Button>

      </Td3>

      {programacaoFiltreded && programacaoFiltreded.length > 0 && (
        <>
          <Table>
            <Thead>
              <Tr>
                <Th>ID:</Th>
                <Th>Título Ordem:</Th>
                <Th>Opções:</Th> {/* Adicionar uma coluna para o botão */}
              </Tr>
            </Thead>
            <Tbody>
              {programacaoFiltreded.map((item, i) => (
                <React.Fragment key={i}>
                  <Tr>
                    <Td>{item.anoOrdem}</Td>
                    <Td>{item.titulo}</Td>
                    <Td>
                      <Button2 onClick={() => toggleRow(i)}>
                        {expandedRows[i] ? 'Esconder Detalhes' : 'Mostrar Detalhes'}
                      </Button2>
                    </Td>
                  </Tr>
                  {expandedRows[i] && ( // Renderiza `servicoFiltreded` apenas se a linha estiver expandida
                    <Tr2>
                      <Td colSpan="3">
                        <Table>
                          <Thead>
                            <Tr>
                              <Th>ID:</Th>
                              <Th>Tarefa:</Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {servicoFiltreded.map((servico, index) => (
                              <Tr key={index} onClick={() => handleEdit(servico)}>
                                <Td>{servico.os}</Td>
                                <Td>{servico.descricao_tarefa}</Td>
                              </Tr>
                            ))}
                          </Tbody>
                        </Table>
                      </Td>
                    </Tr2>
                  )}
                </React.Fragment>
              ))}
            </Tbody>
          </Table>
        </>
      )}



    </Geral>
  );
};


export default Grid;
