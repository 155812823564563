import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Swal from "sweetalert2";
import axios from "axios";
import { toast } from "react-toastify";
import './style.css';
import { BsCardHeading } from "react-icons/bs";
import { IoCloseSharp } from "react-icons/io5";
import { FaMagnifyingGlass } from "react-icons/fa6";


const Box = styled.div`
  width: 100vw;  

`;

const DivMaster = styled.div`
  height: auto; 
  width: 98%; 
  flex: 1;
  padding: 10px;
  box-sizing: border-box;
  margin: 5px;
  gap: 10px;
  background: #f7f7f8; 
  border-radius: 10px; 
  transition: transform 0.1s, background 0.3s;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: nowrap; /* Impede quebra de linha */
  overflow-x: auto; /* Habilita scroll horizontal */
  white-space: nowrap; /* Impede que os itens quebrem para a próxima linha */
  border: 1px solid #ccc;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  h2 {
    text-align: start;
    color: #3a3f42;
  }
`;
const DivBox = styled.div`

  box-sizing: border-box;
  margin: 5px;
  display: flex;
  gap: 10px
`;
const DivCabecario = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between; 
  width: 98%;
  margin: 5px;
  display: flex;
  gap: 10px
`;

const ContainerColuna = styled.div`
  display: flex;
  align-items: flex-start; 
  justify-content: flex-start; /* Alinha à esquerda para crescimento horizontal */
  flex-wrap: nowrap; /* Evita quebra de linha, permite crescimento horizontal */
  padding: 10px; 
  gap: 10px; 
  background-color: #e8e8e8;
  width: 100%;
  height: auto;
  overflow-x: auto; /* Habilita rolagem horizontal */
  white-space: nowrap; /* Impede que o conteúdo quebre para a próxima linha */

`;
const Div = styled.div`
  width: 300px;
  height: 200px;
  display: flex;
  flex-direction: column;

  padding: 10px;
 
  background-color: #f4f4f5;
  border-radius: 10px;
  border: 1px solid #ccc;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  background: rgba(255, 255, 255, 0.1);
  transition: transform 0.2s, background 0.3s;
  flex-shrink: 0; /* Garante que os itens não encolham */
  
  &:hover {

    box-shadow: 0 4px 6px gray;
  }

`;
const Divdata = styled.div`

  display: flex;
  flex-direction: row;
  justify-content: flex-end; /* Alinha o botão ao final */
  align-items: flex-start; /* Mantém o botão no início horizontal */
  width: 100%;
  margin-top: auto; /* Empurra o botão para o final da div */
`;


const Button = styled.button`
  width: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 5px ;
  cursor: pointer;
  border: none;
  background-color: f4f4f5;
  color: #3a3f42;
  font-size: 20px;
  gap: 5px;
  margin-top: 10px;
  transition: transform 0.3s;
  border: 1px solid #ccc;
  border-radius: 10px;
`;

const ButtonNew = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 5px ;
  cursor: pointer;
  border: none;
  background-color: transparent;
  color: #3a3f42;
  font-size: 20px;
  gap: 5px;

  transition: transform 0.3s;

`;

const P = styled.p`
  font-size: 19px;
  color: #3a3f42
  overflow-wrap: break-word; /* Quebra palavras longas */
  white-space: normal; /* Permite que o texto quebre em várias linhas */
  height: 110px;
  overflow: hidden;
`;

const Input = styled.input`
  width: 450px;
  padding: 0 10px;
  border: 1px solid #ccc;
  border-radius: 10px; 
  height: 27px;
  font-size: 20px;
  height: 36px;
  background-color: #f4f4f5;
  color: #3a3f42;
  margin-top: 10px;
`;

const Grid = ({ urlApi, modalNovoQuadro, quadro, fetchQuadro, modalVerQuadro }) => {

  const token = localStorage.getItem('token');
  const id = parseInt(localStorage.getItem('id'));





  //---------------------------------------------------------------------------------------------------------------//
  //Cria nova coluna
  const handleNovoQuadro = () => {
    modalNovoQuadro()
  };


  //---------------------------------------------------------------------------------------------------------------//
  //Deletar lista e cartão
  const showPopUp = () => {
    return new Promise((resolve) => {
      Swal.fire({
        title: `Essa ação é irreversível, tem certeza que gostaria de continuar?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
        customClass: {
          container: 'custom-swal-container', // Estiliza o container principal
          title: 'custom-swal-title', // Estiliza o título
          confirmButton: 'custom-swal-confirm-button', // Estiliza o botão de confirmação
          cancelButton: 'custom-swal-cancel-button', // Estiliza o botão de cancelamento
          popup: 'custom-swal-popup', // Estiliza o popup
          icon: 'custom-swal-icon' // Estiliza o ícone
        },
      }).then((result) => {
        resolve(result.value); // Resolve a Promise com o valor result.value
      });
    });
  };

  const [excluir, setExcluir] = useState(false)
  const handleExcluir = async () => {
    setExcluir(true);
  };



  const handleExcluirCartao = async (quadro) => {
    const userConfirmed = await showPopUp();
    if (!userConfirmed) {
      return;
    }
    try {
      // Tenta realizar a requisição para deletar o usuário
      const { data } = await axios.delete(`${urlApi}/quadro/${quadro.id_quadro}`, {
        headers: { Authorization: `Bearer ${token}` },
      });



    } catch (error) {
      // Tratamento de erros
      console.error('Erro ao deletar o usuário:', error);

      let errorMessage = 'Erro desconhecido ao deletar.';
      if (error.response) {
        errorMessage = error.response.data || errorMessage;
        console.log('Erro na resposta:', error.response);
      } else if (error.request) {
        console.log('Erro na requisição:', error.request);
      } else {
        console.log('Erro na configuração da requisição:', error.message);
      }

      toast.error(errorMessage);

    }



    fetchQuadro();
    setExcluir(false);
  };




  //---------------------------------------------------------------------------------------------------------------//



  const handleEdit = async (quadro) => {
    try {


      modalVerQuadro(quadro); // Continua se o usuário confirmar
    } catch (error) {
      console.error("Erro ao editar o quadro:", error);
    }
  };



  //Controla o botão pesquisar
  const [showInput, setShowInput] = useState(false);
  // Função para alternar a visibilidade do input
  const handleSearchClick = () => {
    setShowInput(!showInput); // Alterna o valor atual (mostra/esconde)
  };

  //states do pesquisar
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [filtroQuadro, setFiltroQuadro] = useState(quadro);

  const handleSearch = async (term) => {
    setIsLoading(true);

    try {
      const filtered = quadro.filter((user) => {
        const columnsToSearch = [
          'titulo_quadro',
        ];

        // Verifica se algum dos valores nas colunas contém o termo de pesquisa
        return columnsToSearch.some((column) => {
          const columnValue = user[column] ? user[column].toLowerCase() : '';
          return columnValue.includes(term.toLowerCase());
        });
      });
      setFiltroQuadro(filtered);
    } catch (error) {
      console.error("Erro ao pesquisar:", error);
    }

    setIsLoading(false);
  };
  useEffect(() => {
    handleSearch(searchTerm);

  }, [searchTerm, quadro]);

  return (
    <div>

      <ContainerColuna>
        <Box >

          <DivMaster className='scroll'>
            <DivCabecario>
              <h2>Meus quadros:</h2>

              <DivBox>
                <Button onClick={() => handleNovoQuadro()} title="Cria novo quadro">
                  <BsCardHeading /> Adicionar quadro
                </Button>
                {!excluir ? (
                  <Button onClick={() => handleExcluir()} title="Excluir">
                    <IoCloseSharp /> Excluir
                  </Button>
                ) : (
                  ''
                )}

                <Input
                  type="text"
                  placeholder="Pesquisar nome do quadro..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </DivBox>
            </DivCabecario>

            <DivBox>



              {filtroQuadro
                .filter((quadro) => quadro.id_criador === id) // Filtra os cards com base no id_coluna
                .map((quadro) => ( // Renderiza os cards filtrados
                  <Div key={quadro.id_quadro} onClick={() => handleEdit(quadro)} type="button" >
                    <P>{quadro.titulo_quadro}</P>
                    <Divdata>
                      {excluir && (
                        <ButtonNew
                          onClick={(event) => {
                            event.preventDefault(); // Impede o comportamento padrão
                            event.stopPropagation(); // Impede a propagação do evento para o Div pai
                            handleExcluirCartao(quadro);
                          }}
                          title="Excluir"
                          type="button"
                        >
                          <IoCloseSharp color={"red"} size={25} />
                        </ButtonNew>
                      )}
                    </Divdata>

                  </Div>
                ))}
            </DivBox>



          </DivMaster>

        </Box>
      </ContainerColuna>

    </div >

  );
};

export default Grid;
