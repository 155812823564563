import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Swal from "sweetalert2";
import axios from "axios";
import { toast } from "react-toastify";
import { FaMagnifyingGlass } from "react-icons/fa6";
import { BsCardHeading } from "react-icons/bs";
import { IoCloseSharp } from "react-icons/io5";
import { TbColumnInsertLeft } from "react-icons/tb";
import { FaPeopleArrows } from "react-icons/fa";
import { MdModeEdit } from "react-icons/md";
import './style.css';
import { parse, format } from 'date-fns';
import { TbReload } from "react-icons/tb";

const Container = styled.div`
  display: flex;
  align-items: flex-start; 
  justify-content: flex-start; /* Alinha à esquerda para crescimento horizontal */
  flex-wrap: nowrap; /* Evita quebra de linha, permite crescimento horizontal */
  gap: 10px; 
  background-color: #e8e8e8;
  width: 100%;

  white-space: nowrap; /* Impede que o conteúdo quebre para a próxima linha */
  div {
    display: flex;
    flex-direction: row;
  }
`;
const ContainerColuna = styled.div`
  display: flex;
  align-items: flex-start; 
  justify-content: flex-start; 
  flex-wrap: nowrap;
  padding: 10px 10px 10px 10px; 
  gap: 10px; 
  background-color: #e8e8e8;
  width: 100%;
  min-height: 70px;
  max-height: calc(100vh - 152px); 
  height: 100vh;

  /* Lógica de scroll horizontal aplicada */
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: nowrap; /* Impede quebra de linha */

  white-space: nowrap; /* Impede que os itens quebrem para a próxima linha */

  position: fixed; /* Fixa o elemento em relação à janela do navegador */
  bottom: 0; /* Mantém o elemento na parte inferior da viewport */
  left: 0;
  right: 0;
  overflow-y: hidden;
`;

const Box = styled.div`
  flex: 0 0 300px; /* Define largura fixa de 300px */
`;

const DivBox = styled.div`
  width: 310px;
  max-height: 77vh;
  flex: 1;
  padding: 7px;
  border: 1px solid #ccc;
  text-align: start;
  box-sizing: border-box;
  margin: 5px;

  background: #f4f4f5; 
  border-radius: 10px; /* Bordas arredondadas */
  backdrop-filter: blur(10px); /* Efeito de desfoque */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Sombra leve */
  transition: transform 0.1s, background 0.3s;

  h3 {
    
    color: #3a3f42;
  }
  
  p {
    
    color: #3a3f42;
  }

  
`;
const DivLista = styled.div`
  max-height: 63vh;
  overflow-y: scroll;
  
`;
const DivCabecario = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between; 
  height: 40px;

  h3 {
    padding-left: 5%;
    white-space: normal; /* Permite quebras de linha automáticas */
    word-break: break-word; /* Quebra a palavra quando não cabe na linha */
  }
  
`;
const DivCabecarioInt = styled.div`
  display: flex;
  flex-direction: row;
`;
const DivCabecarioInt2 = styled.div`
  display: flex;
  flex-direction: row;
  gap: 90px;
`;



const Div = styled.div`
  display: flex;
  width: 250px;
  flex-direction: column;
  align-items: start;
  margin: auto;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
  min-height: 80px;
  background-color: #f4f4f5;
  margin-top: 10px;
  border-radius: 5px;
  cursor: pointer;
  border-radius: 10px; /* Bordas arredondadas */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Sombra leve */

  &:hover {
    box-shadow: 0 4px 6px gray;
  }
  
 
  p {
    color: #3a3f42;
    overflow-wrap: break-word; /* Quebra palavras longas */
    white-space: normal; /* Permite que o texto quebre em várias linhas */
  }
`;

const TextContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Alinha o conteúdo ao início */
  justify-content: flex-start; /* Garante que o conteúdo fique no topo */
  white-space: normal; /* Permite quebra de linha no texto */
`;

const Divdata = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start; /* Mantém os itens no topo */
  width: 100%;
  div {
    display: flex;
    flex-direction: row;
    color: #3a3f42;
    justify-content: start;
    align-items: center;
    gap: 5px;
  }
`;

const P = styled.p`
  font-size: 17px;
  color: #3a3f42;
  overflow-wrap: break-word; /* Quebra palavras longas */
  white-space: normal; /* Permite que o texto quebre em várias linhas */
`;
const Button = styled.button`
  display: flex;
  align-items: center;
  padding: 10px ;
  cursor: pointer;
  border: none;
  background-color: transparent;
  color: #3a3f42;
  font-size: 18px;
  gap: 5px;
  transition: transform 0.3s;
  &:hover {
    transform: scale(1.1);
  }

`;

const Button2 = styled.button`
  display: flex;
  align-items: center;

  cursor: pointer;
  border: none;
  background-color: transparent;
  color: #3a3f42;
  font-size: 20px;

  transition: transform 0.3s;
  &:hover {
    transform: scale(1.1);
  }

`;



const DivButtons = styled.div`

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  margin-left: 15px;
`;
const Input = styled.input`
  width: 450px;
  padding: 0 10px;
  border: 1px solid #ccc;
  border-radius: 10px; 
  height: 27px;
  font-size: 20px;
  height: 36px;
  background-color: #f4f4f5;
  color: #3a3f42;
  margin-top: 10px;
`;
const ButtonNew = styled.button`
  width: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 5px ;
  cursor: pointer;
  border: none;
  background-color: f4f4f5;
  color: #3a3f42;
  font-size: 20px;
  gap: 5px;
  margin-top: 10px;
  transition: transform 0.3s;
  border: 1px solid #ccc;
  border-radius: 10px;
  

`;

const Grid = ({ urlApi, colunas, card, modalNovoCard, fetchCard, fetchColunas, modalEditarCartao, modalNovaColuna, verQuadro, modalCompartilhar, criador, modalEditarNomeLista, checkbox }) => {

  const token = localStorage.getItem('token');
  const id = parseInt(localStorage.getItem('id'));





  //---------------------------------------------------------------------------------------------------------------//
  //Cria nova coluna
  const handleNovoCard = (coluna) => {
    modalNovoCard(coluna)
  };


  //---------------------------------------------------------------------------------------------------------------//
  //Deletar lista e cartão
  const showPopUp = () => {
    return new Promise((resolve) => {
      Swal.fire({
        title: `Essa ação é irreversível, tem certeza que gostaria de continuar?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
        customClass: {
          container: 'custom-swal-container', // Estiliza o container principal
          title: 'custom-swal-title', // Estiliza o título
          confirmButton: 'custom-swal-confirm-button', // Estiliza o botão de confirmação
          cancelButton: 'custom-swal-cancel-button', // Estiliza o botão de cancelamento
          popup: 'custom-swal-popup', // Estiliza o popup
          icon: 'custom-swal-icon' // Estiliza o ícone
        },
      }).then((result) => {
        resolve(result.value); // Resolve a Promise com o valor result.value
      });
    });
  };

  const [excluir, setExcluir] = useState(false)
  const handleExcluir = async () => {
    setExcluir(true);
  };

  const handleExcluirLista = async (coluna) => {
    const userConfirmed = await showPopUp();
    if (!userConfirmed) {
      return;
    }


    const hasCards = filtrandoCards2.some((card) => card.id_coluna === coluna.id_coluna);

    if (hasCards) {
      toast.error('Não é possível excluir a lista porque há cartões associados.');
      return;
    }

    try {
      // Tenta realizar a requisição para deletar o usuário
      const { data } = await axios.delete(`${urlApi}/gerenciamento/${coluna.id_coluna}`, {
        headers: { Authorization: `Bearer ${token}` },
      });


    } catch (error) {
      // Tratamento de erros
      console.error('Erro ao deletar o usuário:', error);

      let errorMessage = 'Erro desconhecido ao deletar.';
      if (error.response) {
        errorMessage = error.response.data || errorMessage;
        console.log('Erro na resposta:', error.response);
      } else if (error.request) {
        console.log('Erro na requisição:', error.request);
      } else {
        console.log('Erro na configuração da requisição:', error.message);
      }

      toast.error(errorMessage);

    }
    fetchColunas();
    setExcluir(false);

  };

  const handleExcluirCartao = async (card) => {
    const userConfirmed = await showPopUp();
    if (!userConfirmed) {
      return;
    }
    try {
      // Tenta realizar a requisição para deletar o usuário
      const { data } = await axios.delete(`${urlApi}/gerenciamento-card/${card.id_card}`, {
        headers: { Authorization: `Bearer ${token}` },
      });



    } catch (error) {
      // Tratamento de erros
      console.error('Erro ao deletar o usuário:', error);

      let errorMessage = 'Erro desconhecido ao deletar.';
      if (error.response) {
        errorMessage = error.response.data || errorMessage;
        console.log('Erro na resposta:', error.response);
      } else if (error.request) {
        console.log('Erro na requisição:', error.request);
      } else {
        console.log('Erro na configuração da requisição:', error.message);
      }

      toast.error(errorMessage);

    }

    fetchCard();
    setExcluir(false);

  };




  //---------------------------------------------------------------------------------------------------------------//
  //Editar cartão
  const handleEdit = (card) => {
    modalEditarCartao(card)
  };

  //Abre o modal 'Nova lista'
  const handleNovaColuna = () => {
    modalNovaColuna()
  };

  //Abre o compartilhar usuário
  const handleCompartilhar = () => {
    modalCompartilhar()
  };

  //Abre o alterar nome lista
  const handleEditarNomeLista = (coluna) => {
    modalEditarNomeLista(coluna)
  };

  //Filtrando o quadros com verQuadro.id_quadro
  const filtrandoCards = card.filter((c) => c.id_quadro === verQuadro.id_quadro);




  //Controla o botão pesquisar
  const [showInput, setShowInput] = useState(false);
  // Função para alternar a visibilidade do input
  const handleSearchClick = () => {
    setShowInput(!showInput); // Alterna o valor atual (mostra/esconde)
  };


  //states do pesquisar
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [filtrandoCards2, setFiltrandoCards2] = useState(card);


  const handleSearch = async (term) => {
    setIsLoading(true);

    try {
      const filtered = filtrandoCards.filter((user) => {
        const columnsToSearch = [
          'titulo_card',
          'status',
          'login_responsavel',
        ];

        // Verifica se algum dos valores nas colunas contém o termo de pesquisa
        return columnsToSearch.some((column) => {
          const columnValue = user[column] ? user[column].toLowerCase() : '';
          return columnValue.includes(term.toLowerCase());
        });
      });
      setFiltrandoCards2(filtered);
    } catch (error) {
      console.error("Erro ao pesquisar:", error);
    }

    setIsLoading(false);
  };
  useEffect(() => {
    handleSearch(searchTerm);

  }, [searchTerm, card]);


  return (
    <div>
      <Container>
        <DivButtons>
          {criador && (
            <>
              <ButtonNew onClick={handleNovaColuna} title="Cria nova lista">
                <TbColumnInsertLeft /> Adicionar lista
              </ButtonNew>
              <ButtonNew onClick={handleCompartilhar} title="Compartilhar o quadro com outros usuários">
                <FaPeopleArrows /> Compartilhar
              </ButtonNew>
            </>

          )}
          
          <Input
            type="text"
            placeholder="Pesquisar titulo, situação e responsável..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          
        </DivButtons>



      </Container>
      <ContainerColuna className='scroll'>
        {colunas
          .filter((coluna) => coluna.id_quadro === verQuadro.id_quadro)
          .map((coluna) => (
            <Box key={coluna.id_coluna} className="coluna" >
              <DivBox>
                <DivCabecario>
                  <h3>{coluna.descricao_coluna}</h3>
                  <DivCabecarioInt>
                    {(criador) && (
                      <Button2 title="Alterar" onClick={() => handleEditarNomeLista(coluna)} type='button'>
                        <MdModeEdit />
                      </Button2>
                    )}
                    {(excluir && criador) && (
                      <Button2 onClick={() => handleExcluirLista(coluna)} title="Excluir" type='button'>
                        <IoCloseSharp color={"red"} size={25} />
                      </Button2>
                    )}
                  </DivCabecarioInt>


                </DivCabecario>

                {/* Filtrando e renderizando os cards que pertencem a essa coluna */}
                <DivLista className='scroll'>
                  {filtrandoCards2
                    .filter((card) => card.id_coluna === coluna.id_coluna) // Filtra os cards com base no id_coluna
                    .map((card) => ( // Renderiza os cards filtrados
                      <Div key={card.id_card} className="card" onClick={() => handleEdit(card)} type="button">
                        <TextContainer >
                          <P title='Titulo do cartão'>{card.titulo_card}</P>
                        </TextContainer>

                        <Divdata>
                          <div>
                            {card.meta_conclusao ? (
                              <P title='Data de entrega do cartão'>{format(parse(card.meta_conclusao, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy')}</P>
                            ) : (
                              <P></P>
                            )}
                            {card.recorrente === 'Sim' ? (
                              <TbReload title='Esse cartão é recorrente' />
                            ) : (
                              <P></P>
                            )}
                          </div>
                          <P title='Situação do cartão'>{card.status}</P>
                          {(excluir && criador) && (
                            <Button2
                              onClick={(event) => {
                                event.preventDefault(); // Impede o comportamento padrão
                                event.stopPropagation(); // Impede a propagação do evento para o Div pai
                                handleExcluirCartao(card);
                              }}
                              title="Excluir"
                              type="button"
                            >
                              <IoCloseSharp color={"red"} size={25} />
                            </Button2>
                          )}
                        </Divdata>

                      </Div>
                    ))}
                </DivLista>

                <DivCabecario>
                  {criador && (
                    <DivCabecarioInt2>
                      <Button onClick={() => handleNovoCard(coluna)} title="Cria novo cartão">
                        <BsCardHeading /> Adicionar cartão
                      </Button>
                      {!excluir && (
                        <Button onClick={() => handleExcluir()} title="Excluir">
                          <IoCloseSharp />
                        </Button>
                      )}
                    </DivCabecarioInt2>
                  )}
                </DivCabecario>

              </DivBox>

            </Box>

          ))}
      </ContainerColuna>

    </div>

  );
};

export default Grid;
