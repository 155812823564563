import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { ImArrowLeft, ImArrowRight } from "react-icons/im";


const Geral = styled.div`
  width: 96%;

  h2{
    text-align: center;
  }
`;
const Table = styled.table`
  width: 700px;
  background-color: #fff;
  padding: 20px 20px;
  box-shadow: 0px 0px 2px #ccc;
  border-radius: 0.5px;
  margin: 5px auto;

`;

export const Tr = styled.tr`
  text-align: center;
  text-decoration: none;
  border: none;
  width: 100%;
  background-color: #fff;
  cursor: pointer;
  transition: transform 0.3s;

  &:hover {
    color: black;
    background-color: #f5f5f5;
  }
}

`;

export const Th = styled.th`
  color: #4f4f4f;
  text-align: start;
  border-bottom: inset;
  padding-bottom: 5px;
  padding: 5px;

`;

export const Td = styled.td`
  word-break: break-word;
  padding-top: 15px;
  padding-bottom: 5px;
  text-align: ${(props) => (props.alignCenter ? "center" : "start")};
  width: ${(props) => (props.width ? props.width : "auto")};
 

`;

export const Tr1 = styled.div`

  width: 100%;
  background-color: #f9f9f9;
  margin-bottom: 15px; /* Espaço entre as linhas */

  div {
    display: flex;
    flex-direction: row; /* Organiza os textos um abaixo do outro */
    gap: 10px; /* Espaço entre os itens */
    padding: 10px;
    background-color: #fff;
    border-radius: 8px; /* Bordas arredondadas */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Adiciona uma leve sombra */
  }
  
`;

const Div2 = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  

  gap: 20px; /* Espaço entre os blocos */
`;

const Div3 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center; /* Centraliza o conteúdo dentro da coluna */
  width: 650px;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  background-color: #f4f4f4;
  gap: 20px; /* Espaço entre as seções de usuário e cursos */
`;


const Grid = ({ exata_CS, anexoCS, modalAnexoCodigo }) => {


  const groupedDataName = anexoCS.reduce((acc, item) => {
    const id = item[0];
    const nome = item[1];

    // Verifica se já existe um objeto com este `id`
    if (!acc.some(obj => obj.id === id)) {
      // Se não existe, adiciona um novo objeto com `id` e `nome`
      acc.push({ id, nome });
    }
    return acc;
  }, []);


  const mergedData = groupedDataName.map(user => {
    // Encontra o departamento correspondente em exata_CS usando o crachá
    const exataData = exata_CS.find(item => item.cracha === String(user.id));

    return {
      ...user,
      departamento: exataData ? exataData.departamento : "Departamento não encontrado",
    };
  });







  const [usuarioFiltrado, setUsuarioFiltrado] = useState([]);


  useEffect(() => {
    const filtrado = anexoCS.filter(item => item[0] === modalAnexoCodigo);

    setUsuarioFiltrado(filtrado);


  }, []);



  const getNome = (anexoCS) => {
    const nomeEncontrado = anexoCS.find(user => user[0] === modalAnexoCodigo);
    return nomeEncontrado ? nomeEncontrado[1] : "Título não encontrado";
  };

  // Chame a função corretamente com o array `anexoCS` como argumento
  const nome = getNome(anexoCS);


  return (

    <Geral>
      <Table>
        <h2>{nome}</h2>
        <Div2>
          <Div3>
            <h3>Certificado de cursos anexados:</h3>
            {usuarioFiltrado.map((usuario, index) => (
              <React.Fragment key={index}>
                <p>{usuario[3]}</p>
              </React.Fragment>
            ))}
          </Div3>
        </Div2>

      </Table>
    </Geral >
  );
};


export default Grid;
