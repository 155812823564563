import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { ImArrowLeft, ImArrowRight } from "react-icons/im";


const Geral = styled.div`
  width: 99%;
  border: 1px solid #ccc;
  padding: 5px;

  h2{
    text-align: center;
  }
`;
const Table = styled.table`
  width: 1250px;
  background-color: #fff;
  padding: 20px 20px;
  box-shadow: 0px 0px 2px #ccc;
  border-radius: 0.5px;
  margin: 5px auto;

`;

export const TdButton = styled.td`
  font-size: 14px;
  font-weight: bold;
  word-break: break-word;
  padding-top: 15px;
  padding-bottom: 5px;
  cursor: pointer;
`;

export const Thead = styled.thead``;

export const Tbody = styled.tbody`
  padding-bottom: 25px; 
`;

export const Tr = styled.tr`
  text-align: center;
  text-decoration: none;
  border: none;
  width: 100%;
  background-color: #fff;
  cursor: pointer;
  transition: transform 0.3s;

  &:hover {
    color: black;
    background-color: #f5f5f5;
  }
}

`;

export const Th = styled.th`
  color: #4f4f4f;
  text-align: start;
  border-bottom: inset;
  padding-bottom: 5px;
  padding: 5px;

`;

export const Td = styled.td`
  word-break: break-word;
  padding-top: 15px;
  padding-bottom: 5px;
  text-align: ${(props) => (props.alignCenter ? "center" : "start")};
  width: ${(props) => (props.width ? props.width : "auto")};
 

`;
export const Td2 = styled.td`
  word-break: break-word;
  padding-top: 15px;
  padding-bottom: 5px;
  align-items: center;

  &:hover {
    color: black;
    background-color: #f5f5f5;
  }

`;



export const Td3 = styled.td`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  span{
    margin: 0px 5px;
  }

`;

const Input = styled.input`
  width: 345px;
  padding: 0 10px;
  border: 1px solid #bbb;
  border-radius: 1px;
  height: 37px;


`;

const Button = styled.button`
  cursor: pointer;
  border: none;
  color: black;
  background-color: transparent;
  transition: transform 0.1s;

  &:hover {
    transform: scale(1.3);
  }
`;

const Div = styled.div`
display: flex;
align-items: center;

  
  span{
    padding: 0px  10px;
  }
`;


export const Tr1 = styled.tr`
  text-align: center;
  text-decoration: none;
  border: none;
  width: 100%;
  background-color: #fff;



}

`;


const Grid = ({ cursosCS, modalCursosCSCodigo }) => {


  const cursosFiltrados = cursosCS.filter(curso => curso[6] === modalCursosCSCodigo);


  const getTituloPorCodigo = (codigo) => {
    const cursoEncontrado = cursosCS.find(curso => curso[6] === codigo);
    return cursoEncontrado ? cursoEncontrado[7] : "Título não encontrado";
  };

  const titulo = getTituloPorCodigo(modalCursosCSCodigo);


  return (

    <Geral>
       <h2>Curso: {titulo}</h2>
      <Table>
        <h3>Não feitos:</h3>
        <Tr1>
          <Td colSpan={3}>
            <Table>
              <Thead>
                <Tr1>
                  <Th>Crachá</Th>
                  <Th>Nome</Th>
                  <Th>Função</Th>
                  <Th>Turno</Th>
                  <Th>Status</Th>
                  <Th>Obser.</Th>
                </Tr1>
              </Thead>
              <Tbody>
                {cursosFiltrados
                  .filter((usuario) => usuario[11] === "Não feito")
                  .map((usuario, index) => (
                    <Tr1 key={index}>
                      <Td>{usuario[0]}</Td>
                      <Td>{usuario[1]}</Td>
                      <Td>{usuario[3]}</Td>
                      <Td>{usuario[5]}</Td>
                      <Td2>{usuario[11]}</Td2>
                      <Td2>{usuario[12]}</Td2>
                    </Tr1>
                  ))}
              </Tbody>
            </Table>
          </Td>
        </Tr1>
      </Table>
      <Table>
        <h3>Feitos:</h3>
        <Tr1>
          <Td colSpan={3}>
            <Table>
              <Thead>
                <Tr1>
                  <Th>Crachá</Th>
                  <Th>Nome</Th>
                  <Th>Função</Th>
                  <Th>Turno</Th>
                  <Th>Status</Th>
                  <Th>Obser.</Th>
                </Tr1>
              </Thead>
              <Tbody>
                {cursosFiltrados
                  .filter((usuario) => usuario[11] === "Feito")
                  .map((usuario, index) => (
                    <Tr1 key={index}>
                      <Td>{usuario[0]}</Td>
                      <Td>{usuario[1]}</Td>
                      <Td>{usuario[3]}</Td>
                      <Td>{usuario[5]}</Td>
                      <Td2>{usuario[11]}</Td2>
                      <Td2>{usuario[12]}</Td2>
                    </Tr1>
                  ))}
              </Tbody>
            </Table>
          </Td>
        </Tr1>
      </Table>
    </Geral >
  );
};


export default Grid;
