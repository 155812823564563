import React, { useState, useEffect } from 'react';
import styled from "styled-components";


const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  gap: 80px;
  @media (max-width: 480px) {
    flex-direction: ${({ hasSelectedVideo }) => (hasSelectedVideo ? 'row' : 'column')};
  }

  @media (max-width: 400px) {
    flex-direction: ${({ hasSelectedVideo }) => (hasSelectedVideo ? 'row' : 'column')};
  }
`;




const ContentWrapper1 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; /* Centraliza os itens */
  z-index: 1;
  color:  #3a3f42;
  gap: 50px;

  h2 {
    margin: 0; 
    color:  #3a3f42;
  }

  h3 {
    margin: 0; 
    color:  #3a3f42;
  }

  p {
    width: 700px;
    padding: 10px;
    color:  #3a3f42;
  }

  @media (max-width: 480px) {
    flex-direction: ${({ hasSelectedVideo }) => (hasSelectedVideo ? 'row' : 'column')};
  }

  @media (max-width: 400px) {
    flex-direction: ${({ hasSelectedVideo }) => (hasSelectedVideo ? 'row' : 'column')};
  }
`;


const P = styled.p`
  font-size: 20px;
  text-align: left; 
  width: 700px; 
  padding: 0px 0px 5px 0px;
  color:  #3a3f42;
`;






const Grid = ({ urlApi }) => {

  const token = localStorage.getItem('token');
  const [videoUrlApresentacao, setvideoUrlApresentacao] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchVideo = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${urlApi}/reproduzir/videos/inicio-painel-monitoramento.mp4`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          const errorText = await response.text(); // Captura o erro exato retornado pelo servidor
          throw new Error(`Erro ao carregar o vídeo: ${errorText}`);
        }

        const blob = await response.blob();
        const videoObjectUrl = URL.createObjectURL(blob);
        setvideoUrlApresentacao(videoObjectUrl);
      } catch (error) {
        console.error("Erro ao carregar o vídeo:", error);
        setError(`Erro ao carregar o vídeo: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchVideo();

    return () => {
      if (videoUrlApresentacao) URL.revokeObjectURL(videoUrlApresentacao); // Limpeza do Blob URL ao desmontar
    };
  }, [urlApi, token]);




  const [videoUrlQuadro01, setvideoUrlQuadro01] = useState(null);
  useEffect(() => {
    const fetchVideo01 = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${urlApi}/reproduzir/videos/quadro01.mp4`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          const errorText = await response.text(); // Captura o erro exato retornado pelo servidor
          throw new Error(`Erro ao carregar o vídeo: ${errorText}`);
        }

        const blob = await response.blob();
        const videoObjectUrl = URL.createObjectURL(blob);
        setvideoUrlQuadro01(videoObjectUrl);
      } catch (error) {
        console.error("Erro ao carregar o vídeo:", error);
        setError(`Erro ao carregar o vídeo: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchVideo01();

    return () => {
      if (videoUrlQuadro01) URL.revokeObjectURL(videoUrlQuadro01); // Limpeza do Blob URL ao desmontar
    };
  }, [urlApi, token]);




  const [videoUrlQuadro02, setvideoUrlQuadro02] = useState(null);
  useEffect(() => {
    const fetchVideo02 = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${urlApi}/reproduzir/videos/quadro02.mp4`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          const errorText = await response.text(); // Captura o erro exato retornado pelo servidor
          throw new Error(`Erro ao carregar o vídeo: ${errorText}`);
        }

        const blob = await response.blob();
        const videoObjectUrl = URL.createObjectURL(blob);
        setvideoUrlQuadro02(videoObjectUrl);
      } catch (error) {
        console.error("Erro ao carregar o vídeo:", error);
        setError(`Erro ao carregar o vídeo: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchVideo02();

    return () => {
      if (videoUrlQuadro02) URL.revokeObjectURL(videoUrlQuadro02); // Limpeza do Blob URL ao desmontar
    };
  }, [urlApi, token]);





  const [videoUrlQuadro03, setvideoUrlQuadro03] = useState(null);
  useEffect(() => {
    const fetchVideo03 = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${urlApi}/reproduzir/videos/quadro03.mp4`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          const errorText = await response.text(); // Captura o erro exato retornado pelo servidor
          throw new Error(`Erro ao carregar o vídeo: ${errorText}`);
        }

        const blob = await response.blob();
        const videoObjectUrl = URL.createObjectURL(blob);
        setvideoUrlQuadro03(videoObjectUrl);
      } catch (error) {
        console.error("Erro ao carregar o vídeo:", error);
        setError(`Erro ao carregar o vídeo: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchVideo03();

    return () => {
      if (videoUrlQuadro03) URL.revokeObjectURL(videoUrlQuadro03); // Limpeza do Blob URL ao desmontar
    };
  }, [urlApi, token]);





  const [videoUrlCartao01, setvideoUrlCartao01] = useState(null);
  useEffect(() => {
    const fetchVideo04 = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${urlApi}/reproduzir/videos/cartao01.mp4`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          const errorText = await response.text(); // Captura o erro exato retornado pelo servidor
          throw new Error(`Erro ao carregar o vídeo: ${errorText}`);
        }

        const blob = await response.blob();
        const videoObjectUrl = URL.createObjectURL(blob);
        setvideoUrlCartao01(videoObjectUrl);
      } catch (error) {
        console.error("Erro ao carregar o vídeo:", error);
        setError(`Erro ao carregar o vídeo: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchVideo04();

    return () => {
      if (videoUrlCartao01) URL.revokeObjectURL(videoUrlCartao01); // Limpeza do Blob URL ao desmontar
    };
  }, [urlApi, token]);






  const [videoUrlCartao02, setvideoUrlCartao02] = useState(null);
  useEffect(() => {
    const fetchVideo05 = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${urlApi}/reproduzir/videos/cartao02.mp4`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          const errorText = await response.text(); // Captura o erro exato retornado pelo servidor
          throw new Error(`Erro ao carregar o vídeo: ${errorText}`);
        }

        const blob = await response.blob();
        const videoObjectUrl = URL.createObjectURL(blob);
        setvideoUrlCartao02(videoObjectUrl);
      } catch (error) {
        console.error("Erro ao carregar o vídeo:", error);
        setError(`Erro ao carregar o vídeo: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchVideo05();

    return () => {
      if (videoUrlCartao02) URL.revokeObjectURL(videoUrlCartao02); // Limpeza do Blob URL ao desmontar
    };
  }, [urlApi, token]);






  const [videoUrlCartao03, setvideoUrlCartao03] = useState(null);
  useEffect(() => {
    const fetchVideo06 = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${urlApi}/reproduzir/videos/cartao03.mp4`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          const errorText = await response.text(); // Captura o erro exato retornado pelo servidor
          throw new Error(`Erro ao carregar o vídeo: ${errorText}`);
        }

        const blob = await response.blob();
        const videoObjectUrl = URL.createObjectURL(blob);
        setvideoUrlCartao03(videoObjectUrl);
      } catch (error) {
        console.error("Erro ao carregar o vídeo:", error);
        setError(`Erro ao carregar o vídeo: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchVideo06();

    return () => {
      if (videoUrlCartao03) URL.revokeObjectURL(videoUrlCartao03); // Limpeza do Blob URL ao desmontar
    };
  }, [urlApi, token]);





  const [videoUrlCartao04, setvideoUrlCartao04] = useState(null);
  useEffect(() => {
    const fetchVideo07 = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${urlApi}/reproduzir/videos/cartao04.mp4`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          const errorText = await response.text(); // Captura o erro exato retornado pelo servidor
          throw new Error(`Erro ao carregar o vídeo: ${errorText}`);
        }

        const blob = await response.blob();
        const videoObjectUrl = URL.createObjectURL(blob);
        setvideoUrlCartao04(videoObjectUrl);
      } catch (error) {
        console.error("Erro ao carregar o vídeo:", error);
        setError(`Erro ao carregar o vídeo: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchVideo07();

    return () => {
      if (videoUrlCartao04) URL.revokeObjectURL(videoUrlCartao04); // Limpeza do Blob URL ao desmontar
    };
  }, [urlApi, token]);






  const [videoUrlCartao05, setvideoUrlCartao05] = useState(null);
  useEffect(() => {
    const fetchVideo08 = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${urlApi}/reproduzir/videos/cartao05.mp4`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          const errorText = await response.text(); // Captura o erro exato retornado pelo servidor
          throw new Error(`Erro ao carregar o vídeo: ${errorText}`);
        }

        const blob = await response.blob();
        const videoObjectUrl = URL.createObjectURL(blob);
        setvideoUrlCartao05(videoObjectUrl);
      } catch (error) {
        console.error("Erro ao carregar o vídeo:", error);
        setError(`Erro ao carregar o vídeo: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchVideo08();

    return () => {
      if (videoUrlCartao05) URL.revokeObjectURL(videoUrlCartao05); // Limpeza do Blob URL ao desmontar
    };
  }, [urlApi, token]);






  const [videoUrlCartao06, setvideoUrlCartao06] = useState(null);
  useEffect(() => {
    const fetchVideo09 = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${urlApi}/reproduzir/videos/cartao06.mp4`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          const errorText = await response.text(); // Captura o erro exato retornado pelo servidor
          throw new Error(`Erro ao carregar o vídeo: ${errorText}`);
        }

        const blob = await response.blob();
        const videoObjectUrl = URL.createObjectURL(blob);
        setvideoUrlCartao06(videoObjectUrl);
      } catch (error) {
        console.error("Erro ao carregar o vídeo:", error);
        setError(`Erro ao carregar o vídeo: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchVideo09();

    return () => {
      if (videoUrlCartao06) URL.revokeObjectURL(videoUrlCartao06); // Limpeza do Blob URL ao desmontar
    };
  }, [urlApi, token]);








  return (
    <>
      <Container>
        <Container>
          <ContentWrapper1>
            <h1>Tutorial Painel de Monitoramento:</h1>
            
            {loading && <p>Carregando vídeo...</p>}
            {error && <p style={{ color: 'red' }}>{error}</p>}
            {videoUrlApresentacao && (
              <div>
                <P>Olá, vamos aprender mais sobre as funcionalidades do Painel de Monitoramento:</P>
                <video controls style={{ width: '700px' }}>
                  <source src={videoUrlApresentacao} type="video/mp4" />
                  Seu navegador não suporta a reprodução de vídeo.
                </video>
                <p>O Painel de Monitoramento é uma ferramenta poderosa para organização e gerenciamento de projetos. Ele permite a criação de quadros onde as informações podem ser centralizadas e acessadas facilmente. Os quadros facilitam a visualização e o compartilhamento das informações entre membros de uma equipe, garantindo que todos estejam alinhados com as atividades e metas. Além disso, é possível criar listas que ajudam a categorizar e priorizar tarefas de acordo com a necessidade do projeto. Cartões e tarefas podem ser adicionados dentro dessas listas para detalhar atividades específicas e acompanhar seu progresso. Isso possibilita um acompanhamento detalhado e organizado, fornecendo uma visão clara de tudo o que precisa ser feito e por quem, melhorando a eficiência do trabalho colaborativo e o cumprimento dos prazos estabelecidos. </p>
              </div>
            )}
          </ContentWrapper1>

          <ContentWrapper1>
            
            {loading && <p>Carregando vídeo...</p>}
            {error && <p style={{ color: 'red' }}>{error}</p>}
            {videoUrlQuadro01 && (
              <div>
                <P>1.0 - Agora vamos aprender mais sobre os quadros:</P>
                <video controls style={{ width: '700px' }}>
                  <source src={videoUrlQuadro01} type="video/mp4" />
                  Seu navegador não suporta a reprodução de vídeo.
                </video>
                <p>O painel é dividido em dois campos principais: "Meus Quadros" e "Quadros que foram compartilhados com você". No campo "Meus Quadros", os usuários podem gerenciar seus próprios quadros, incluindo criar novos, pesquisar, excluir e acessar todos os quadros de forma conveniente. No campo "Quadros que foram compartilhados com você", os usuários têm acesso aos quadros que outras pessoas compartilharam com eles, facilitando a colaboração e o compartilhamento de informações entre usuários. Essas funcionalidades ajudam a organizar e gerenciar o trabalho de forma eficiente dentro da plataforma.</p>
              </div>
            )}

           
            {videoUrlQuadro02 && (
              <div>
                <P>1.1 - Como criar, editar, excluir e visualizar quadros:</P>
                <video controls style={{ width: '700px' }}>
                  <source src={videoUrlQuadro02} type="video/mp4" />
                  Seu navegador não suporta a reprodução de vídeo.
                </video>
                <p>O processo de criação de um quadro envolve a adição de um título e uma descrição, seguida pela confirmação da criação. A edição de título e  da descrição é feita ao clicar no quadro existente, fazendo as alterações necessárias e salvando-as. A exclusão de um quadro requer clicar em "x" e confirmar a ação.</p>
              </div>
            )}
          </ContentWrapper1>

          <ContentWrapper1>
            
            {videoUrlQuadro03 && (
              <div>
                <P>2.0 - Como criar, editar, excluir listas:</P>
                <video controls style={{ width: '700px' }}>
                  <source src={videoUrlQuadro03} type="video/mp4" />
                  Seu navegador não suporta a reprodução de vídeo.
                </video>
                <p>Para criar uma lista, clique em "Adicionar lista", digite um nome e confirme a criação. Para editar o nome de uma lista, clique no ícone de lápis, faça a alteração e salve. Se desejar excluir uma lista, clique no "X" na lista, e confirme a exclusão clicando novamente no "X" que aparece no topo. Esses passos descrevem a manipulação das listas para melhor organização no quadro escolhido.</p>
              </div>
            )}
          </ContentWrapper1>

          <ContentWrapper1>
            
            {videoUrlCartao01 && (
              <div>
                <P>3.0 - Como criar cartões:</P>
                <video controls style={{ width: '700px' }}>
                  <source src={videoUrlCartao01} type="video/mp4" />
                  Seu navegador não suporta a reprodução de vídeo.
                </video>
                <p>Primeiramente, deve-se escolher uma lista e clicar em "Adicionar cartão". Seguindo, é necessário designar um responsável pelo cartão e definir uma data de entrega. Há também a opção de definir o cartão como recorrente. Além disso, é preciso escolher um título e adicionar uma descrição detalhada. Para complementar, pode-se anexar arquivos relevantes ao cartão. Finalmente, após todos os detalhes estarem preenchidos e revisados, é preciso clicar em "Criar cartão" e confirmar a ação. Este processo assegura que todas as informações necessárias para a execução de uma tarefa estão organizadas e facilmente acessíveis.</p>
                <p>Ao compartilhar um quadro, é possível criar cartões e designar como responsável o usuário com quem o quadro foi compartilhado.</p>
                <p>Obs.: O cartão quando é recorrente, tem vencimento mensal.</p>
              </div>
            )}
            

            
            {videoUrlCartao02 && (
              <div>
                <P>3.1 - Como editar o cartão:</P>
                <video controls style={{ width: '700px' }}>
                  <source src={videoUrlCartao02} type="video/mp4" />
                  Seu navegador não suporta a reprodução de vídeo.
                </video>
                <p>Primeiramente, é necessário clicar no cartão que se deseja editar. Entre as opções disponíveis, é possível modificar o título do cartão, atribuir uma situação, escolher uma nova data de entrega ou ajustar se o cartão será recorrente ou não. Além disso, a descrição do cartão pode ser alterada. Caso haja necessidade, é possível anexar um arquivo, bem como substituir um arquivo anteriormente anexado.</p>
                <p>O Criador do cartão pode editar o Titulo, Responsável, Situação, Entrega, Recorrente, Valor, Descrição, Anexo do cartão, criar, editar e excluir as tarefas.</p>
                <p>O Co-criador possui as mesmas atriubições do Criador do cartão, podendo editar o Titulo, Co-criador, Responsável, Situação, Entrega, Recorrente, Valor, Descrição, Anexo do cartão, criar, editar e excluir as tarefas.</p>
                <p>O Responsável pode criar, editar e excluir as tarefas do cartão.</p>
              </div>
            )}

           
            {videoUrlCartao03 && (
              <div>
                <P>3.2 - Criar, editar e excluir tarefas:</P>
                <video controls style={{ width: '700px' }}>
                  <source src={videoUrlCartao03} type="video/mp4" />
                  Seu navegador não suporta a reprodução de vídeo.
                </video>
                <p>Para gerenciar tarefas dentro de um cartão, é necessário clicar no cartão desejado e depois no botão "Criar/Alterar Tarefas". A partir daí, você pode criar a descrição da tarefa, atribuir um responsável, definir uma data de entrega e salvar. As tarefas criadas são exibidas na parte inferior do cartão. Se precisar editar ou excluir uma tarefa, use o mesmo botão "Criar/Alterar Tarefas" e o ícone de lápis ao lado da data da tarefa para alterações. Para excluir, clique na lixeira e confirme a ação.</p>
              </div>
            )}


            {videoUrlCartao04 && (
              <div>
                <P>3.3 - Responder a tarefa:</P>
                <video controls style={{ width: '700px' }}>
                  <source src={videoUrlCartao04} type="video/mp4" />
                  Seu navegador não suporta a reprodução de vídeo.
                </video>
                <p>O processo de atribuição de tarefas a um usuário envolve a responsabilidade de executar e responder à tarefa no painel de monitoramento. Para responder, o usuário deve acessar o cartão específico, verificar as tarefas e confirmar se é o responsável. Em seguida, o usuário deve clicar no botão correspondente à tarefa, preencher a ação realizada no campo "Resposta" e salvá-la. Após concluir essas etapas, o usuário pode marcar o checkbox para confirmar a conclusão da tarefa.</p>
              </div>
            )}
            

            
            {videoUrlCartao05 && (
              <div>
                <P>3.4 - Movendo o cartão de lista:</P>
                <video controls style={{ width: '700px' }}>
                  <source src={videoUrlCartao05} type="video/mp4" />
                  Seu navegador não suporta a reprodução de vídeo.
                </video>
                <p>O processo de mover um cartão entre listas envolve alguns passos simples. Primeiro, é necessário clicar no cartão desejado. Depois, no canto superior deve-se acionar o botão "mover cartão", o que permitirá escolher a lista de destino através da opção "mover para". Após selecionar a lista, clique em "salvar" para efetivar a mudança e confirme a ação para finalizar o processo de movimentação do cartão.</p>
              </div>
            )}
          </ContentWrapper1>

          <ContentWrapper1>

            {videoUrlCartao06 && (
              <div>
                <P>4.0 - Compartilhando quadro:</P>
                <video controls style={{ width: '700px' }}>
                  <source src={videoUrlCartao06} type="video/mp4" />
                  Seu navegador não suporta a reprodução de vídeo.
                </video>
                <p>Para compartilhar quadro, o usuário deve clicar no botão "Compartilhar" na parte superior do quadro e selecionar o usuário com quem deseja compartilhar. Após realizar esse procedimento, o quadro compartilhado aparecerá no campo "Quadros Compartilhados com Você" para o usuário selecionada.</p>
              </div>
            )}
          </ContentWrapper1>
        </Container>



      </Container>
    </>
  );
};

export default Grid;
