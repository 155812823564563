import axios from "axios";
import React, { useRef } from "react";
import styled from "styled-components";
import { toast } from "react-toastify";
import { ImCheckmark } from "react-icons/im";
import Swal from "sweetalert2";



const FormContainer = styled.form`
  width: auto;
  background-color: #f4f4f5;
  display: flex; 
  align-items: flex-start;
  flex-direction: column;

`;

const Div = styled.div`
  width:97%;
  background-color:#f4f4f5;
  display: flex; 
  padding: 10px 10px;
  align-items: flex-end;
  gap: 20px 0px;
  flex-direction: row;
 
`;


const TextAreaContainer = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  
  textarea{
    font-size: 18px;
    width: 490px;
    height: 100px;
    background-color: #f4f4f5;
    border: 1px solid #ccc;
    border-radius: 10px; 
    color:#3a3f42;
    padding: 5px;
  }


`;

const InputArea = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  


`;


const InputTitle = styled.input`
  width: 480px;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 0px 10px;
  background-color: #f4f4f5;
  color:#3a3f42;
  font-size: 18px;
`;

const Label = styled.label`
  color: #3a3f42;
  font-weight: bold;
  font-family: 'Open Sans', sans-serif;
  font-size: 20px;
  margin: 0px 5px;

  @media (max-width: 500px) {
    font-size: 16px;
  }
`;



const Button = styled.button`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px ;
  cursor: pointer;
  border: none;
  background-color: green;
  color: #fff;
  font-size: bold;
  font-size: 20px;
  gap: 5px;
  margin-top: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;


`;


const Form = ({ urlApi, modalNovoQuadro, fetchQuadro }) => {
  const ref = useRef();


  //Token da api
  const token = localStorage.getItem('token');
  const id = localStorage.getItem('id');



  const showPopUp = () => {
    return new Promise((resolve) => {
      Swal.fire({
        title: "Deseja confirmar?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
        customClass: {
          container: 'custom-swal-container', // Estiliza o container principal
          title: 'custom-swal-title', // Estiliza o título
          confirmButton: 'custom-swal-confirm-button', // Estiliza o botão de confirmação
          cancelButton: 'custom-swal-cancel-button', // Estiliza o botão de cancelamento
          popup: 'custom-swal-popup', // Estiliza o popup
          icon: 'custom-swal-icon' // Estiliza o ícone
        },
      }).then((result) => {
        resolve(result.value);
      });
    });
  };






  const handleSubmit = async (e) => {
    e.preventDefault();
    const user = ref.current;


    const id_criadorValue = id;
    const titulo_quadroValue = user.titulo_quadro.value;
    const descricao_quadroValue = user.descricao_quadro.value;




    if (!titulo_quadroValue || !descricao_quadroValue) {
      return toast.warn("Preencha todos os campos!");
    } else {
      const userConfirmed = await showPopUp(); // Aguarde a resolução da Promise



      if (!userConfirmed) {
        return;
      }
      try {
        const requestData1 = {

          id_criador: id_criadorValue,
          titulo_quadro: titulo_quadroValue,
          descricao_quadro: descricao_quadroValue,

        };
        const response1 = await axios.post(`${urlApi}/quadro`, requestData1, { headers: { Authorization: `Bearer ${token}` } });

        setTimeout(function () {
          modalNovoQuadro()
        }, 1000);

        fetchQuadro();
      } catch (error) {
        if (error.response) {
          const errorMessage = error.response.data.error;
          toast.error(errorMessage);
        }
      }

    }

  };







  return (
    <div >
      <FormContainer ref={ref} onSubmit={handleSubmit}>


        <Div>
          <InputArea>
            <Label>Título:</Label>
            <InputTitle
              placeholder="Digite um título...."
              name="titulo_quadro"
              type="text"
            />
          </InputArea>
        </Div>


        <Div>
          <TextAreaContainer>
            <Label>Descrição:</Label>
            <textarea id="descricao_quadro" name="msg" placeholder="Digite uma descrição...."></textarea>
          </TextAreaContainer>
        </Div>






        <Div>
            <Button title="Salvar novo quadro" type="submit" >
              Criar quadro
            </Button>
        </Div>
      </FormContainer>
      <style jsx global>{`
      .swal2-container.custom-swal-container {
        z-index: 9999; 
      }
    `}</style>
    </div>

  );
};

export default Form;


