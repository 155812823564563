import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { ImArrowLeft, ImArrowRight } from "react-icons/im";


const Geral = styled.div`
  width: 96%;

  h2{
    text-align: center;
  }
`;
const Table = styled.table`
  width: 700px;
  background-color: #fff;
  padding: 20px 20px;
  box-shadow: 0px 0px 2px #ccc;
  border-radius: 0.5px;
  margin: 5px auto;

`;

export const Thead = styled.thead``;

export const Tbody = styled.tbody`
  padding-bottom: 25px; 
`;

export const Tr = styled.tr`
  text-align: center;
  text-decoration: none;
  border: none;
  width: 100%;
  background-color: #fff;
  cursor: pointer;
  transition: transform 0.3s;

  &:hover {
    color: black;
    background-color: #f5f5f5;
  }
}

`;

export const Th = styled.th`
  color: #4f4f4f;
  text-align: start;
  border-bottom: inset;
  padding-bottom: 5px;
  padding: 5px;

`;

export const Td = styled.td`
  word-break: break-word;
  padding-top: 15px;
  padding-bottom: 5px;
  text-align: ${(props) => (props.alignCenter ? "center" : "start")};
  width: ${(props) => (props.width ? props.width : "auto")};
 

`;

export const Tr1 = styled.tr`
  text-align: center;
  text-decoration: none;
  border: none;
  background-color: #fff;
}

`;
const Div = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 20px; /* Espaço entre os blocos */
`;

const Div2 = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px; /* Espaço entre os blocos */
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f4f4f4;
  padding: 20px;
`;

const Div3 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center; /* Centraliza o conteúdo dentro da coluna */
  width: 400px;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  background-color: #f4f4f4;
  gap: 20px; /* Espaço entre as seções de usuário e cursos */
`;




const Grid = ({ exata_CS, anexoCS, modalCursosAnexoCodigo, cursosCS }) => {


  const filtrado = cursosCS.filter(item => item[0] === modalCursosAnexoCodigo);

  const groupedDataName = anexoCS.reduce((acc, item) => {
    const id = item[0];
    const nome = item[1];

    // Verifica se já existe um objeto com este `id`
    if (!acc.some(obj => obj.id === id)) {
      // Se não existe, adiciona um novo objeto com `id` e `nome`
      acc.push({ id, nome });
    }
    return acc;
  }, []);


  const mergedData = groupedDataName.map(user => {
    // Encontra o departamento correspondente em exata_CS usando o crachá
    const exataData = exata_CS.find(item => item.cracha === String(user.id));

    return {
      ...user,
      departamento: exataData ? exataData.departamento : "Departamento não encontrado",
    };
  });







  const [usuarioFiltrado, setUsuarioFiltrado] = useState([]);


  useEffect(() => {
    const filtrado = anexoCS.filter(item => item[0] === modalCursosAnexoCodigo);

    setUsuarioFiltrado(filtrado);


  }, []);



  const getNome = (anexoCS) => {
    const nomeEncontrado = anexoCS.find(user => user[0] === modalCursosAnexoCodigo);
    return nomeEncontrado ? nomeEncontrado[1] : "Título não encontrado";
  };
  const nome = getNome(anexoCS);





  return (

    <Geral>
      <Table>
        <h2>{nome}</h2>

        <Div>
          <Div2>
            <h3>Cursos Obrigatórios:</h3>
            <Table>
              <Thead>
                <Tr1>
                  <Th></Th>
                  <Th></Th>
                  <Th></Th>
                </Tr1>
              </Thead>
              <Tbody>
                {filtrado
                  .filter((usuario) => usuario[11] === "Não feito")
                  .map((usuario, index) => (
                    <Tr1>
                      <Td>{usuario[7]}</Td>
                      <Td>{usuario[11]}</Td>
                      <Td>{usuario[12]}</Td>
                    </Tr1>
                  ))}
              </Tbody>
              <Tbody>
                {filtrado
                  .filter((usuario) => usuario[11] === "Feito")
                  .map((usuario, index) => (
                    <Tr1>
                      <Td>{usuario[7]}</Td>
                      <Td>{usuario[11]}</Td>
                      <Td>{usuario[12]}</Td>
                    </Tr1>
                  ))}
              </Tbody>
            </Table>
          </Div2>

          <Div>
            <Div3>
              <h3>Certificado de cursos anexados:</h3>
              {usuarioFiltrado.map((usuario, index) => (
                <React.Fragment key={index}>
                  <p>{usuario[3]}</p>
                </React.Fragment>
              ))}
            </Div3>
          </Div>
        </Div>
      </Table>

    </Geral >
  );
};


export default Grid;
