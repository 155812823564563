import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { toast } from "react-toastify";
import { ImCheckmark, ImLoop2 } from "react-icons/im";
import Swal from "sweetalert2";

const FormContainer = styled.form`
  width: 100%;
  background-color: #fff;
  padding: 20px 20px;
  box-shadow: 0px 0px 2px #ccc;
  border-radius: 0.5px;
  margin: 0 auto; 
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
`;

const DivGeral = styled.form`
  background-color: #fff;
  padding: 20px 20px;
  flex-direction: row;
`;

const Div = styled.td`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const InputArea = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  padding: 10px;

  select {
    width: 220px;
    padding: 0 10px;
    border: 1px solid #bbb;
    border-radius: 1px;
    height: 37px;
  }
`;

const Input = styled.input`
  width: 200px;
  padding: 0 10px;
  border: 1px solid #bbb;
  border-radius: 1px;
  height: 37px;

  
`;

const InputName = styled.input`
  width: 320px;
  padding: 0 10px;
  border: 1px solid #bbb;
  border-radius: 1px;
  height: 37px;
`;

const Label = styled.label`
  color: #4f4f4f;
  font-weight: bold;
  font-size: 17px;  
`;

const ButtonContainer = styled.div`
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: center;
  margin: 1px 1px 1px 1px;
`;

const ButtonLimpar = styled.button`
  padding-bottom: 10px;
  cursor: pointer;
  border: none;
  background-color:   #fff;
  font-weight: bold;
  font-size: 17px;
  margin: 8px;
  transition: transform 0.1s;
  &:hover {
    transform: scale(1.1);
  }
`;

const Button = styled.button`
  cursor: pointer;
  border: none;
  background-color:   #fff;
  font-weight: bold;
  font-size: 17px;
  margin: 8px;
  transition: transform 0.1s;
  &:hover {
    transform: scale(1.1);
  }
`;

const Div2 = styled.td`
  display: flex;
  flex-direction:column;

`;

export const Tr = styled.tr`
  text-align: center;
`;

export const Td = styled.td`
  word-break: break-word;
  padding-top: 15px;
  padding-bottom: 5px;
  text-align: ${(props) => (props.alignCenter ? "center" : "start")};
  width: ${(props) => (props.width ? props.width : "auto")};

  select {
    margin-right: 5px;
  }
 
`;

export const Th = styled.th`
  color: #4f4f4f;
  text-align: start;
  border-bottom: inset;
  padding-bottom: 5px;

`;

const Form = ({ onEdit, setOnEdit, urlApi, fetchUsuarios, acessos }) => {


  


  const [senha, setSenha] = useState(null);
  const [repetirSenha, setRepetirSenha] = useState(null);


  const ref = useRef(null);


  //onEdit que vem do Grid para ser editado
  useEffect(() => {
    if (onEdit) {
      const user = ref.current;

      user.login.value = onEdit.login;
      user.nome.value = onEdit.nome;
      user.email.value = onEdit.email;
      user.setor.value = onEdit.setor;
    };

  }, [onEdit]);


  // tokens
  const token = localStorage.getItem('token');
  const usuario = localStorage.getItem('usuario');
  const id = localStorage.getItem('id');


  //popUp que confirma a operação
  const showPopUp = () => {
    return new Promise((resolve) => {
      Swal.fire({
        title: "Deseja confirmar a ação?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
        customClass: {
          container: 'custom-swal-container',
        },
      }).then((result) => {
        resolve(result.value); // Resolve a Promise com o valor result.value
      });
    });
  };


  const saveLog = async (login, descricao) => {
    try {
      const logData = {
        login,
        descricao,
      };

      const response = await fetch(`${urlApi}/log`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify(logData),
      });

      if (!response.ok) {
        throw new Error('Failed to save log');
      }
    } catch (error) {
      console.error('Erro ao salvar log:', error);
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    const user = ref.current;

    // Coleta os valores dos campos
    const loginValue = user.login.value;
    const senhaValue = user.senha.value;
    const nomeValue = user.nome.value;
    const repetirSenhaValue = user.repetirSenha.value;
    const emailValue = user.email.value;
    const setorValue = user.setor.value;

    // Validação de campos obrigatórios
    if (!loginValue || !nomeValue || !emailValue || !setorValue) {
      return toast.warn("Preencha todos os campos!");
    }

    // Verificação de correspondência das senhas
    if (senhaValue !== repetirSenhaValue) {
      return toast.error("As senhas não coincidem!");
    }

    // Confirmação do usuário
    const userConfirmed = await showPopUp();
    if (!userConfirmed) {
      return;
    }

    // Construção do array de acessos
    const acessos = [
      { modulo: "portal", tipo_acesso: user.portal?.value || null },
      { modulo: "treinamentos", tipo_acesso: user.treinamentos?.value || null },
      { modulo: "planejamentoEntressafra", tipo_acesso: user.planejamentoentressafra?.value || null },
      { modulo: "boletinsWeb", tipo_acesso: user.boletinsweb?.value || null },
      { modulo: "gestao", tipo_acesso: user.gestao?.value || null },
      { modulo: "painelMonitoramento", tipo_acesso: user.painelmonitoramento?.value || null },
    ];
    


    try {
      if (onEdit) {
        // Atualiza os acessos
        await axios.put(
          `${urlApi}/portal/acessos/${onEdit.id_usuario}`,
          { acessos },  // Inclua o id_usuario aqui
          { headers: { Authorization: `Bearer ${token}` } }
        );

        // Atualiza o usuário
        await axios
          .put(`${urlApi}/portal/${onEdit.id_usuario}`, {
            login: loginValue,
            nome: nomeValue,
            email: emailValue,
            setor: setorValue,
          }, { headers: { Authorization: `Bearer ${token}` } })
          .then(async ({ data }) => {
            toast.success(data);

            // Atualiza a senha, se necessário
            if (senhaValue) {
              await handleSubmitSenha();
            }

            await saveLog(usuario, `Usuário ${usuario} editou o login ${loginValue}`);
          })
          .catch(async (error) => {
            const errorMessage = error.response?.data.error || 'Erro desconhecido';
            console.error("Error:", errorMessage);
            toast.error("Erro ao editar: " + errorMessage);
            await saveLog(usuario, `Erro ao editar o login ${loginValue} pelo usuário ${usuario}: ${errorMessage}`);
          });
      } else {
        await axios.post(
          `${urlApi}/portal/acessos`,
          { id_usuario: id, acessos },  // Inclua o id_usuario aqui
          { headers: { Authorization: `Bearer ${token}` } }
        );

        await axios
          .post(`${urlApi}/portal`, {
            login: loginValue,
            senha: senhaValue,
            nome: nomeValue,
            email: emailValue,
            setor: setorValue,
          }, { headers: { Authorization: `Bearer ${token}` } })
          .then(async ({ data }) => {
            toast.success(data);
            await saveLog(usuario, `Usuário ${usuario} adicionou o login ${loginValue}`);
          })
          .catch(async (error) => {
            const errorMessage = error.response?.data.error || 'Erro desconhecido';
            console.error("Error:", errorMessage);
            toast.error("Erro ao adicionar: " + errorMessage);
            await saveLog(usuario, `Erro ao adicionar o login ${loginValue} pelo usuário ${usuario}: ${errorMessage}`);
          });
      }
    } catch (error) {
      console.error('Erro inesperado:', error);
      toast.error('Erro inesperado.');
      await saveLog(usuario, `Erro inesperado ao processar o login ${loginValue} pelo usuário ${usuario}`);
    }

    // Atualização e limpeza de campos
    fetchUsuarios();
    setOnEdit(null);

    // Limpeza dos campos do formulário
    user.login.value = "";
    setSenha('');
    setRepetirSenha('')
    user.nome.value = "";
    user.email.value = "";
    user.setor.value = ""; // Adicionado para limpar o setor
  };


  const handleSubmitSenha = async (e) => {
    const user = ref.current;

    const senhaValue = user.senha.value;
    const repetirSenhaValue = user.repetirSenha.value;

    if (senhaValue !== repetirSenhaValue) {
      return toast.error("As senhas não coincidem!");
    }


    try {
      if (onEdit) {
        await axios
          .put(`${urlApi}/portal/senha/${onEdit.id_usuario}`, {
            senha: senhaValue,
          }, { headers: { Authorization: `Bearer ${token}` } })

          .catch(async (error) => {
            if (error.response) {
              const errorMessage = error.response.data.error || 'Erro desconhecido';
              const statusCode = error.response.status;
              const url = error.config.url;

              console.log("Erro na atualização da senha:", {
                mensagem: errorMessage,
                statusCode: statusCode,
                url: url,
                response: error.response.data,
              });

              toast.error(`Erro ao editar a senha: ${errorMessage} (Status: ${statusCode})`);
            } else if (error.request) {
              // Erro relacionado à requisição em si (por exemplo, sem resposta do servidor)
              console.error('Erro de requisição:', {
                request: error.request,
                url: error.config.url,
              });
              toast.error('Erro ao enviar a requisição. Verifique sua conexão.');
            } else {
              // Outros tipos de erro (por exemplo, erro na configuração)
              console.error('Erro inesperado:', {
                mensagem: error.message,
                config: error.config,
              });
              toast.error('Erro inesperado. Verifique os detalhes no console.');
            }
          });
      }
    } catch (error) {
      // Captura erros inesperados
      console.error('Erro inesperado durante a tentativa de editar a senha:', error);
      toast.error('Erro inesperado. Verifique os detalhes no console.');
    }

    fetchUsuarios();
    setOnEdit(null);
    setSenha('');
    setRepetirSenha('')

  };



  const [accessValues, setAccessValues] = useState({
    portal: "",
    gestao: "",
    boletinsweb: "",
    painelmonitoramento: "",
    treinamentos: "",
    planejamentoentressafra: ""
  });

  // Atualizar os valores de acesso com base no array acessos quando onEdit for verdadeiro
  useEffect(() => {
    if (onEdit && acessos) {
      const filteredAcessos = acessos.filter(acesso => acesso.id_usuario === onEdit.id_usuario);

      const newAccessValues = {
        portal: "",
        gestao: "",
        boletinsweb: "",
        painelmonitoramento: "",
        treinamentos: "",
        planejamentoentressafra: ""
      };

      // Preenche os valores específicos de tipo_acesso para cada módulo do usuário
      filteredAcessos.forEach((acesso) => {
        switch (acesso.modulo) {
          case "portal":
            newAccessValues.portal = acesso.tipo_acesso;
            break;
          case "gestao":
            newAccessValues.gestao = acesso.tipo_acesso;
            break;
          case "boletinsWeb":
            newAccessValues.boletinsweb = acesso.tipo_acesso;
            break;
          case "painelMonitoramento":
            newAccessValues.painelmonitoramento = acesso.tipo_acesso;
            break;
          case "treinamentos":
            newAccessValues.treinamentos = acesso.tipo_acesso;
            break;
          case "planejamentoEntressafra":
            newAccessValues.planejamentoentressafra = acesso.tipo_acesso;
            break;
          default:
            break;
        }
      });

      setAccessValues(newAccessValues);
    }
  }, [onEdit?.id_usuario, acessos]);

  return (
    <>
      <FormContainer onSubmit={handleSubmit}>
        <DivGeral ref={ref}>
          <Div>
            <Div2>
              <InputArea>
                <Label>Login:</Label>
                <Input name="login" type="text" placeholder="Digite o login" />
              </InputArea>

            </Div2>

            <Div2>
              <InputArea>
                <Label>Senha:</Label>
                <Input name="senha" type="password" value={senha} onChange={(e) => setSenha(e.target.value)} placeholder="Digite sua senha" />
              </InputArea>

            </Div2>

            <Div2>
              <InputArea>
                <Label>Repetir a senha:</Label>
                <Input name="repetirSenha" type="password" value={repetirSenha} onChange={(e) => setRepetirSenha(e.target.value)} placeholder="Digite sua senha novamente"
                />
              </InputArea>


            </Div2>

          </Div>
          <Div>
            <Div2>
              <InputArea>
                <Label>Nome Completo:</Label>
                <Input name="nome" type="text" placeholder="Digite seu nome" />
              </InputArea>

            </Div2>

            <Div2>
              <InputArea>
                <Label>Email:</Label>
                <Input name="email" type="text" placeholder="Digite seu nome" />
              </InputArea>

            </Div2>
            <Div2>
              <InputArea>
                <Label>Setor:</Label>
                <select name="setor" >
                  <option value="" disabled selected>Escolha....</option>
                  <option value="Adminitração Industrial">Adminitração Industrial</option>
                  <option value="Adminitração Manut. Ind.">Adminitração Manutenção Industrial</option>
                  <option value="Caldeira">Caldeira</option>
                  <option value="Comercial">Comercial</option>
                  <option value="Compras">Compras</option>
                  <option value="Contabilidade">Contabilidade</option>
                  <option value="Controladoria">Controladoria</option>
                  <option value="Controle Agricola">Controle Agrícola</option>
                  <option value="Controle Oficina Agricola">Controle Oficina Agrícola</option>
                  <option value="Controle Transporte">Controle Transporte</option>
                  <option value="Departamento de Pessoal">Departamento de Pessoal</option>
                  <option value="Diretoria">Diretoria</option>
                  <option value="Financeiro">Financeiro</option>
                  <option value="Laboratório">Laboratório</option>
                  <option value="Manutenção da Borracharia">Manutenção da Borracharia</option>
                  <option value="Manutenção Elétrica">Manutenção Elétrica</option>
                  <option value="Manutenção Mecanica">Manutenção Mecânica</option>
                  <option value="Moagem Cana">Moagem Cana</option>
                  <option value="Tecnologia da Informação">Tecnologia da Informação</option>
                  <option value="Topografia">Topografia</option>
                  <option value="Tratos Culturais">Tratos Culturais</option>
                  <option value="Terceiro">Terceiro</option>
                </select>
              </InputArea>
            </Div2>
          </Div>

          {onEdit ? (
            <>
              <Div>
                <Div2>
                  <InputArea>
                    <Label>Portal:</Label>
                    <select name="portal" value={accessValues.portal} onChange={(e) => setAccessValues({ ...accessValues, portal: e.target.value })}>
                      <option value="" disabled>Tipo de usuário</option>
                      <option value="A">Administrador</option>
                      <option value="G">Gestor</option>
                      <option value="U">Usuário</option>
                    </select>
                  </InputArea>
                </Div2>

                <Div2>
                  <InputArea>
                    <Label>Gestão:</Label>
                    <select name="gestao" value={accessValues.gestao} onChange={(e) => setAccessValues({ ...accessValues, gestao: e.target.value })}>
                      <option value="" disabled>Tipo de usuário</option>
                      <option value="A">Administrador</option>
                      <option value="G">Gestor</option>
                      <option value="U">Usuário</option>
                      <option value="N">Não pode acessar</option>
                    </select>
                  </InputArea>
                </Div2>

                <Div2>
                  <InputArea>
                    <Label>Boletins Web:</Label>
                    <select name="boletinsweb" value={accessValues.boletinsweb} onChange={(e) => setAccessValues({ ...accessValues, boletinsweb: e.target.value })}>
                      <option value="" disabled>Tipo de usuário</option>
                      <option value="Y">Sim, pode acessar</option>
                      <option value="N">Não pode acessar</option>
                    </select>
                  </InputArea>
                </Div2>
              </Div>

              <Div>
                <Div2>
                  <InputArea>
                    <Label>Painel Monitoramento:</Label>
                    <select name="painelmonitoramento" value={accessValues.painelmonitoramento} onChange={(e) => setAccessValues({ ...accessValues, painelmonitoramento: e.target.value })}>
                      <option value="" disabled>Tipo de usuário</option>
                      <option value="Y">Sim, pode acessar</option>
                      <option value="N">Não pode acessar</option>
                    </select>
                  </InputArea>
                </Div2>

                <Div2>
                  <InputArea>
                    <Label>Treinamentos:</Label>
                    <select name="treinamentos" value={accessValues.treinamentos} onChange={(e) => setAccessValues({ ...accessValues, treinamentos: e.target.value })}>
                      <option value="" disabled>Tipo de usuário</option>
                      <option value="Y">Sim, pode acessar</option>
                      <option value="N">Não pode acessar</option>
                    </select>
                  </InputArea>
                </Div2>

                <Div2>
                  <InputArea>
                    <Label>Planejamento Entressafra:</Label>
                    <select name="planejamentoentressafra" value={accessValues.planejamentoentressafra} onChange={(e) => setAccessValues({ ...accessValues, planejamentoentressafra: e.target.value })}>
                      <option value="" disabled>Tipo de usuário</option>
                      <option value="Y">Sim, pode acessar</option>
                      <option value="N">Não pode acessar</option>
                    </select>
                  </InputArea>
                </Div2>
              </Div>
            </>

          ) : (
            null
          )}

        </DivGeral>

        <Div>
          <Div2>
            <ButtonContainer>
              <ButtonLimpar title="Atualizar" type="button" onClick={() => window.location.reload()}>
                <ImLoop2 color={"gold"} /> Atualizar
              </ButtonLimpar>
              <Button title="Salvar" type="submit" >
                <><ImCheckmark color={"green"} /> Salvar </>
              </Button>
            </ButtonContainer>
          </Div2>
        </Div>

      </FormContainer >

    </>
  );
};

export default Form;


