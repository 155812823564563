import { useState, useEffect } from 'react';
import axios from 'axios';

//const url = "http://192.168.100.103:8850/api";
const url = "/api";
const token = localStorage.getItem('token');


export const useCardHook = () => {
    const [card, setCard] = useState([]);

    const statusOrder = ["A Fazer", "Programado", "Em Andamento", "Pausado", "Concluído", "Cancelado"];

    const fetchCard = async () => {
        try {
            const res = await axios.get(`${url}/gerenciamento-card`, {
                headers: { Authorization: `Bearer ${token}` }
            });

            // Ordena os cards por data de criação (decrescente)
            const sortedData = res.data.sort((a, b) => new Date(b.data_criacao) - new Date(a.data_criacao));

            // Ordena os cards por status usando o array de ordem
            sortedData.sort((a, b) => statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status));

            setCard(sortedData);
        } catch (error) {
            console.error('Erro ao buscar dados de card:', error);
        }
    };


    useEffect(() => {
        fetchCard();
    }, [token]);

    
    return { card, fetchCard };
};
