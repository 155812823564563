import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Swal from "sweetalert2";
import './style.css'
import { FaMagnifyingGlass } from "react-icons/fa6";

const Box = styled.div`
  width: 100vw;  
`;

const DivMaster = styled.div`
  height: auto; 
  width: 98%; 
  flex: 1;
  padding: 10px;
  box-sizing: border-box;
  margin: 5px;
  gap: 10px;
  background: #f7f7f8; 
  border-radius: 10px; 
  transition: transform 0.1s, background 0.3s;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: nowrap; /* Impede quebra de linha */
  overflow-x: auto; /* Habilita scroll horizontal */
  white-space: nowrap; /* Impede que os itens quebrem para a próxima linha */
  border: 1px solid #ccc;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  h2 {
    text-align: start;
    color: #3a3f42;
  }
`;
const DivBox = styled.div`
  text-align: center;
  box-sizing: border-box;
  margin: 5px;
  display: flex;
  gap: 10px
`;

const ContainerColuna = styled.div`
  display: flex;
  align-items: flex-start; 
  justify-content: flex-start; /* Alinha à esquerda para crescimento horizontal */
  flex-wrap: nowrap; /* Evita quebra de linha, permite crescimento horizontal */
  padding: 10px; 
  gap: 10px; 
  background-color: #e8e8e8;
  width: 100%;
  height: auto;
  overflow-x: auto; /* Habilita rolagem horizontal */
  white-space: nowrap; /* Impede que o conteúdo quebre para a próxima linha */

`;
const Div = styled.div`
  width: 300px; /* Largura fixa para os cartões */
  height: 200px; /* Altura fixa dos cartões */
  display: flex;
  flex-direction: column;
  justify-content: space-between; 
  align-items: flex-start; 
  padding: 10px;
  border: 1px solid #ccc;
  background-color: #f4f4f5;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  background: rgba(255, 255, 255, 0.1); 
  transition: transform 0.2s, background 0.3s;
  
  &:hover {
    box-shadow: 0 4px 6px gray;
  }

  p {
    color: #3a3f42;
    font-size: 18px;
  }
  
  div{
    text-align: left;
  }
`;

const P = styled.p`
  font-size: 19px;
  color: #3a3f42
  text-align: left;
  overflow-wrap: break-word; /* Quebra palavras longas */
  white-space: normal; /* Permite que o texto quebre em várias linhas */
  height: 110px;
  overflow: hidden;
`;

const DivCabecario = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between; 
  width: 98%;
  margin: 5px;
  display: flex;
  gap: 10px
`;

const Input = styled.input`
  width: 450px;
  padding: 0 10px;
  border: 1px solid #ccc;
  border-radius: 10px; 
  height: 27px;
  font-size: 20px;
  height: 36px;
  background-color: #f4f4f5;
  color: #3a3f42;
  margin-top: 10px;
`;

const Button = styled.button`
  width: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 5px ;
  cursor: pointer;
  border: none;
  background-color: f4f4f5;
  color: #3a3f42;
  font-size: 20px;
  gap: 5px;
  margin-top: 10px;
  transition: transform 0.3s;
  border: 1px solid #ccc;
  border-radius: 10px;
`;
const Grid = ({ quadro, setVerQuadro, visualizador, usuarios }) => {


  const id = parseInt(localStorage.getItem('id'));


  const resultado = quadro
    .filter(quadro => usuarios.some(usuario => usuario.id_usuario === quadro.id_criador))
    .map(quadro => {
      const usuario = usuarios.find(usuario => usuario.id_usuario === quadro.id_criador);
      return {
        id_criador: quadro.id_criador, // Adiciona o id_criador
        id_quadro: quadro.id_quadro,   // Adiciona o id_quadro
        titulo_quadro: quadro.titulo_quadro,
        setor: usuario ? usuario.setor : "Setor não encontrado",
        nome: usuario ? usuario.nome : "Nome não encontrado",
      };
    });




  //---------------------------------------------------------------------------------------------------------------//

  const showPopUp2 = (titulo) => {
    return new Promise((resolve) => {
      Swal.fire({
        title: `Gostaria de visualizar o quadro: "${titulo}" ?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
        customClass: {
          container: 'custom-swal-container', // Estiliza o container principal
          title: 'custom-swal-title', // Estiliza o título
          confirmButton: 'custom-swal-confirm-button', // Estiliza o botão de confirmação
          cancelButton: 'custom-swal-cancel-button', // Estiliza o botão de cancelamento
          popup: 'custom-swal-popup', // Estiliza o popup
          icon: 'custom-swal-icon' // Estiliza o ícone
        },
      }).then((result) => {
        resolve(result.value); // Resolve a Promise com o valor result.value
      });
    });
  };


  const handleEdit = async (quadro) => {
    try {
      const userConfirmed = await showPopUp2(quadro.titulo_quadro); // Passa o título do quadro
      if (!userConfirmed) {
        return; // Se o usuário não confirmar, interrompe a execução
      }

      setVerQuadro(quadro); // Continua se o usuário confirmar
    } catch (error) {
      console.error("Erro ao editar o quadro:", error);
    }
  };


  //Controla o botão pesquisar
  const [showInput, setShowInput] = useState(false);
  // Função para alternar a visibilidade do input
  const handleSearchClick = () => {
    setShowInput(!showInput); // Alterna o valor atual (mostra/esconde)
  };

  //states do pesquisar
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [filtroQuadro, setFiltroQuadro] = useState(resultado);

  const handleSearch = async (term) => {
    setIsLoading(true);

    try {
      const filtered = resultado.filter((user) => {
        const columnsToSearch = [
          'titulo_quadro',
          'nome',
          'setor',
        ];

        // Verifica se algum dos valores nas colunas contém o termo de pesquisa
        return columnsToSearch.some((column) => {
          const columnValue = user[column] ? user[column].toLowerCase() : '';
          return columnValue.includes(term.toLowerCase());
        });
      });
      setFiltroQuadro(filtered);
    } catch (error) {
      console.error("Erro ao pesquisar:", error);
    }

    setIsLoading(false);
  };
  useEffect(() => {
    handleSearch(searchTerm);

  }, [searchTerm, quadro]);

  useEffect(() => {
    if (filtroQuadro.length === 0) {
      setFiltroQuadro(resultado);
      // Verifica o valor de `filtroQuadro`
    }
  }, [filtroQuadro]);


  return (
    <div>

      <ContainerColuna>
        <Box >

          <DivMaster className='scroll'>

            <DivCabecario>
              <h2>Quadros que foram compartilhados com você:</h2>
              <Input
                type="text"
                placeholder="Pesquisar quadro, nome ou setor..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </DivCabecario>

            <DivBox>
              {filtroQuadro
                .filter(q =>
                  visualizador.some(v => v.id_quadro === q.id_quadro && v.id_usuario === id) &&
                  q.id_criador !== id // Adiciona a condição para excluir quadros do criador
                )
                .map((quadro) => {
                  return (
                    <Div key={quadro.id_quadro} onClick={() => handleEdit(quadro)} type="button">
                      <P>{quadro.titulo_quadro}</P>

                      <div>
                        <p>{quadro.nome}</p>
                        <p>{quadro.setor}</p>
                      </div>
                    </Div>
                  );
                })
              }
            </DivBox>


          </DivMaster>



        </Box>

      </ContainerColuna>

    </div>

  );
};

export default Grid;
