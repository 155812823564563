import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";

const Geral = styled.div`
  width: 800px;
  border: 1px solid #ccc;
  padding: 20px;
  background-color: #fff;
  display: flex;
  align-items: center;
  flex-direction: column;
  
`;
const Table = styled.table`
  width: 780px;
  background-color: #fff;
  padding: 20px 20px;
  box-shadow: 0px 0px 2px #ccc;
  border-radius: 0.5px;
  margin: 5px auto;

`;

export const TdButton = styled.td`
  font-size: 14px;
  font-weight: bold;
  word-break: break-word;
  padding-top: 15px;
  padding-bottom: 5px;
  cursor: pointer;
`;

export const Thead = styled.thead``;

export const Tbody = styled.tbody`
  padding-bottom: 25px; 
`;

export const Tr = styled.tr`
  text-align: center;


`;

export const Th = styled.th`
  color: #4f4f4f;
  text-align: start;
  border-bottom: inset;
  padding-bottom: 5px;


`;

export const Td = styled.td`
  word-break: break-word;
  padding-top: 15px;
  padding-bottom: 5px;
  text-align: ${(props) => (props.alignCenter ? "center" : "start")};
  width: ${(props) => (props.width ? props.width : "auto")};
 

`;

export const Td1 = styled.td`
  padding-top: 15px;
  text-align: center;


`;

export const Td2 = styled.td`
  word-break: break-word;
  padding-top: 15px;
  padding-bottom: 5px;
  text-align: ${(props) => (props.alignCenter ? "center" : "start")};
  width: ${(props) => (props.width ? props.width : "auto")};
 
`;

export const Td3 = styled.td`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  span{
    margin: 0px 5px;
  }

`;



const Div = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  margin-top: 25px;

  span{
    padding: 0px  10px;
  }
`;



const Grid = ({ onEdit, urlApi }) => {

  //Token da api
  const token = localStorage.getItem('token');



  const [filteredUsers, setFilteredUsers] = useState([]);


  // Função para buscar eventos
  const getEventos = async () => {
    try {
      const res = await axios.get(`${urlApi}/treinamento/eventos-funcionarios`, {
        headers: { Authorization: `Bearer ${token}` }
      });

      const eventosFuncio = res.data.map(item => ({
        id: item[0],
        idEvent: item[1],
        cracha: item[2],
        nome: item[3],
        status: item[7]

      }));

      setFilteredUsers(eventosFuncio); // Certifique-se de que res.data seja um array
    } catch (err) {
      console.log("Conexão falhou", err);
    }
  };

  console.log(filteredUsers)
  useEffect(() => {
    getEventos();
  }, []);




  const filteredUsersList = () => {
    const idToFilter = onEdit.eventoId; // Obtém o ID do onEdit
    return filteredUsers.filter(user => user.idEvent === idToFilter); // Filtra o filteredUsers pelo ID
  };

  const currentItems = filteredUsersList();

  return (

    <Geral>
      <h2>{onEdit.titulo}</h2>
      <Div>
        <h3>Participantes Presentes</h3>
        <Table >

          <Thead>
            <Tr>
              <Th>Crachá:</Th>
              <Th>Nome:</Th>

            </Tr>
          </Thead>
          <Tbody>
            {currentItems
              .filter((item) => item.status === 'P') // Filtrando apenas itens onde a 6ª posição é 'P'
              .map((item, i) => (
                <Tr key={i}>
                  <Td>{item.cracha}</Td> {/* CRACHÀ */}
                  <Td>{item.nome}</Td> {/* NOME */}
                </Tr>
              ))}
          </Tbody>


        </Table>

      </Div>

      <Div>
        <h3>Participantes Faltantes</h3>
        <Table >

          <Thead>
            <Tr>
              <Th>Crachá:</Th>
              <Th>Nome:</Th>

            </Tr>
          </Thead>
          <Tbody>
            {currentItems
              .filter((item) => item.status === 'F') // Filtrando apenas itens onde a 6ª posição é 'P'
              .map((item, i) => (
                <Tr key={i}>
                  <Td>{item.cracha}</Td> {/* CRACHÀ */}
                  <Td>{item.nome}</Td> {/* NOME */}
                </Tr>
              ))}
          </Tbody>


        </Table>

      </Div>

      <Div>
        <h3>Participantes Imprevistos</h3>
        <Table >

          <Thead>
            <Tr>
              <Th>Crachá:</Th>
              <Th>Nome:</Th>

            </Tr>
          </Thead>
          <Tbody>
            {currentItems
              .filter((item) => item.status === 'I') // Filtrando apenas itens onde a 6ª posição é 'P'
              .map((item, i) => (
                <Tr key={i}>
                  <Td>{item.cracha}</Td> {/* CRACHÀ */}
                  <Td>{item.nome}</Td> {/* NOME */}
                </Tr>
              ))}
          </Tbody>


        </Table>

      </Div>
    </Geral>
  );
};


export default Grid;
