import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { ImArrowLeft, ImArrowRight } from "react-icons/im";


const Geral = styled.div`
  width: 96%;


`;
const Table = styled.table`
  width: 700px;
  background-color: #fff;
  padding: 20px 20px;
  box-shadow: 0px 0px 2px #ccc;
  border-radius: 0.5px;
  margin: 5px auto;

`;

export const Tr = styled.tr`
  text-align: center;
  text-decoration: none;
  border: none;
  width: 100%;
  background-color: #fff;
  cursor: pointer;
  transition: transform 0.3s;

  &:hover {
    color: black;
    background-color: #f5f5f5;
  }
}

`;

export const Th = styled.th`
  color: #4f4f4f;
  text-align: start;
  border-bottom: inset;
  padding-bottom: 5px;
  padding: 5px;

`;

export const Td = styled.td`
  word-break: break-word;
  padding-top: 15px;
  padding-bottom: 5px;
  text-align: ${(props) => (props.alignCenter ? "center" : "start")};
  width: ${(props) => (props.width ? props.width : "auto")};
 

`;

export const Tr1 = styled.div`

  width: 100%;
  background-color: #f9f9f9;
  margin-bottom: 15px; /* Espaço entre as linhas */

  div {
    display: flex;
    flex-direction: row; /* Organiza os textos um abaixo do outro */
    gap: 10px; /* Espaço entre os itens */
    padding: 10px;
    background-color: #fff;
    border-radius: 8px; /* Bordas arredondadas */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Adiciona uma leve sombra */
  }
  
`;

const Div2 = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  

  gap: 20px; /* Espaço entre os blocos */
`;

const Div3 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center; /* Centraliza o conteúdo dentro da coluna */
  width: 650px;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  background-color: #f4f4f4;
  gap: 20px; /* Espaço entre as seções de usuário e cursos */
`;


const Grid = ({ modalCargosRepetidosCodigo, cargosRepitidosCS, cursosCargoCS }) => {



  const countMap = cargosRepitidosCS.reduce((map, item) => {
    const key = item[3]; // Valor do índice [1]
    map[key] = (map[key] || 0) + 1;
    return map;
  }, {});




  // Passo 2: Filtrar apenas os itens de cargosRepitidosCS que têm valores duplicados
  const semCargosDuplicados = cargosRepitidosCS.filter(item => countMap[item[3]] > 1);
  //console.log('duplicados', semCargosDuplicados);

  const cursosPorCargo = {};

  // Passo 2: Agrupar os cursos pelo ID do cargo
  cursosCargoCS.forEach(curso => {
    const key = `${curso[0]}-${curso[1]}-${curso[2]}`; // Usar o ID do cargo como chave
    if (!cursosPorCargo[key]) {
      cursosPorCargo[key] = { // Se não existir, inicializa um novo array
        cargoId: key,
        cursos: []
      };
    }
    cursosPorCargo[key].cursos.push(curso); // Adiciona o curso ao cargo
  });


  // Passo 3: Criar o objeto final com a estrutura desejada
  const resultadoFinal = semCargosDuplicados.map(cargo => {
    const key = `${cargo[0]}-${cargo[1]}-${cargo[2]}`; // Usar o ID do cargo como chave
    return {
      ...cargo, // Inclui os detalhes do cargo
      cursos: cursosPorCargo[key] ? cursosPorCargo[key].cursos : [] // Adiciona os cursos, se houver
    };
  });


  //--------------------------------------------------------------------------------------
  const resultadoFiltrado = resultadoFinal.filter(item =>
    cursosCargoCS.some(curso => curso[3] === item[3]) // Verifica se item[3] de resultadoFinal existe em cursosCargoCS
  );


  const [cargoFiltrado, setCargoFiltrado] = useState([]);


  useEffect(() => {
    const filtrado = resultadoFiltrado.filter(item => item[3] === modalCargosRepetidosCodigo);

    // Atualiza o estado com o resultado filtrado
    setCargoFiltrado(filtrado);


  }, []);






  return (

    <Geral>
      <Table>

        <Div2>
          {cargoFiltrado.map((usuario, index) => (
            <Div3 key={index}>
              
              <Tr1>
                <div>
                  <p>cód: {usuario[2]}</p> 
                  <p>cargo: {usuario[3]}</p> 
                </div>
              </Tr1>

              
              {usuario.cursos.map((curso, cursoIndex) => (
                <div key={cursoIndex}>
                  <p>Descrição: {curso[5]}</p> 
                </div>
              ))}
            </Div3>
          ))}
        </Div2>
      </Table>
    </Geral >
  );
};


export default Grid;
