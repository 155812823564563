import { useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import debounce from 'lodash/debounce';

export const useDebouncedPutAnexo = (urlApi, id_card, field, token, editarCartao) => {
  const [isFocused, setIsFocused] = useState(false);
  const [fieldValue, setFieldValue] = useState(editarCartao.image);

  const debouncedPutRequest = useCallback(
    debounce((newValue) => {
      axios
        .put(
          `${urlApi}/gerenciamento-card/cartao-anexo/${id_card}`,
          { [field]: newValue },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((response) => {
          console.log(`${field} atualizado com sucesso:`, response.data);
        })
        .catch((error) => {
          console.error(`Erro ao atualizar o ${field}:`, error);
        });
    }, 500), // 500ms de debounce
    [urlApi, id_card, field, token] // Dependências necessárias
  );

  const handleChange = (event) => {
    const file = event.target.files[0]; // Pega o primeiro arquivo
    if (file) {
      setFieldValue(file.name); // Defina o nome do arquivo no state
    }
  };

  const handleBlur = () => {
    debouncedPutRequest(fieldValue); // Envie o nome do arquivo
    setIsFocused(false);
  };

  useEffect(() => {
    return () => {
      debouncedPutRequest.cancel(); // Cancelar a requisição pendente no unmount
    };
  }, [debouncedPutRequest]);

  return {
    isFocused,
    setIsFocused,
    fieldValue,
    setFieldValue,
    handleChange,
    handleBlur,
  };
};
